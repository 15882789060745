
<template>
  <div class="container">
    <div class="row">
      <div class="col-md-2 col-xs-12">&nbsp;</div>
      <div class="col-md-8 col-md-offset-2 col-xs-12">
        <div class="omb_login">
          <h3 class="omb_authTitle">Login or <a href="/register">Sign up</a></h3>
          <div class="row omb_row-sm-offset-3 omb_socialButtons">
            <div class="col-xs-4 col-sm-3">
              <a href @click.prevent="logInWithFacebook" class="btn btn-md btn-block omb_btn-facebook">
                <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook-f' }"/>
                <span class="hidden-xs"> Facebook</span>
              </a>
            </div>
            <div class="col-xs-4 col-sm-3">
              <a ref="btnGGLogin" href @click.prevent="" class="btn btn-md btn-block omb_btn-google">
                <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'google-plus-g' }"/>
                <span class="hidden-xs"> Google+</span>
              </a>
            </div>
            <div class="col-xs-4 col-sm-3">
              <a href @click.prevent="logInWithTwitter" class="btn btn-md btn-block omb_btn-twitter">
                <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'twitter' }"/>
                <span class="hidden-xs"> Twitter</span>
              </a>
            </div>
          </div>
          <div class="row omb_row-sm-offset-3 omb_loginOr">
            <div class="col-xs-12 col-sm-9">
              <hr class="omb_hrOr">
              <span class="omb_spanOr">or</span>
            </div>
          </div>

          <div class="row omb_row-sm-offset-3">
            <div class="col-xs-12 col-sm-9">
              <form name="form" @submit.prevent="handleLogin" class="omb_loginForm">
                <div 
                  v-if="message" 
                  class="alert" 
                  :class="successful ? 'alert-success' : 'alert-danger'" 
                  role="alert">{{message}}</div>
                <div class="row-item">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text"><font-awesome-icon icon="user" /></div>
                    </div>
                    <input 
                      ref="first"
                      v-model="user.email"
                      v-validate="'required|email|max:100'"
                      type="email"
                      class="form-control"
                      :class="errors.has('email') ? 'is-invalid' : ''"
                      placeholder="Email address" 
                      name="email"
                    />
                  </div>
                  <span v-if="errors.has('email')" class="help-block">{{errors.first('email')}}</span>
                </div>
                <div class="row-item">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text"><font-awesome-icon icon="lock" /></div>
                    </div>
                    <input 
                      v-model="user.password"
                      v-validate="'required'"
                      type="password"
                      class="form-control"
                      :class="errors.has('password') ? 'is-invalid' : ''"
                      placeholder="Password" 
                      name="password"
                    />
                  </div>
                  <span v-if="errors.has('password')" class="help-block">Password is required</span>
                </div>
                <button :disabled="loading" class="btn btn-primary btn-block" type="submit">
                  <span v-show="loading" class="spinner-border spinner-border-sm"></span> <span>Login</span>
                </button>
              </form>
            </div>
          </div>
          <div class="row omb_row-sm-offset-3">
            <div class="col-xs-12 col-sm-12">
              <!-- <p class="omb_forgotPwd2"><a href="#">Forgot password?</a></p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import User from "../models/user";
// import firebase from "firebase";
import firebase from "../firebase/firebase";

export default {
  name: 'Login',
  data() {
    return {
      user: new User('', '', '', ''),
      loading: false,
      successful: false,
      message: ''
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  mounted() {
    this.$refs.first.focus();
    this.setupFB();
    this.setupGG();
	},
  created() {
    if(this.loggedIn) {
      this.$router.push('root');
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;
      this.$validator.validateAll().then(isValid => {
        if(!isValid) {
          this.loading = false;
          return;
        }
        if(this.user.email && this.user.password) {
          this.$store.dispatch('auth/login', this.user).then(data => {
            //console.log(data);
            this.message = data.msg;
            if(data && data.err >= 0) {
              this.successful = true;
              //this.$router.push('root');
              this.$router.push(this.$route.query.redirect || '/')
            } else {
              this.successful = false;
              this.loading = false;
            }
          });
        }
      }, error => {
        this.loading = false;
        this.message = (error.response && error.response.data) || error.message || error.toString();
        this.successful = false;
      });
    },
    logInWithFacebook() {
      var vthis = this;
      window.FB.login(function(res) {
        //console.log(res);
        if (res.authResponse) {
          // console.log("Logged into your webpage and Facebook.");
          // Now you can redirect the user or do an AJAX request to
          // a PHP script that grabs the signed request from the cookie.
          window.FB.api('/me', 'GET', { fields: 'id,name,email,picture' },
            fbuser => {
              // console.log(fbuser);
              // console.log(fbuser.id);
              // console.log(fbuser.name);
              // console.log(fbuser.email || "");
              // console.log(fbuser.picture.data.url);
              //// login by fbId.
              let formData = {
                "fbid": fbuser.id,
                "username": fbuser.name,
                "email": fbuser.email || "",
                "avt": fbuser.picture.data.url
              };
              vthis.$store.dispatch('auth/fblogin', formData).then(data => {
                // console.log(data);
                vthis.message = data.msg;
                if(data && data.err >= 0) {
                  vthis.successful = true;
                  vthis.$router.push(vthis.$route.query.redirect || '/');
                } else {
                  vthis.successful = false;
                  vthis.loading = false;
                }
              });
            }
          );
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      }, {scope: 'public_profile,email'});
      return false;
    },
    async setupFB() {
      // console.log("In setupFB");
      await this.loadFacebookSDK(document, "script", "facebook-jssdk");
      await this.initFacebook();
    },
    async initFacebook() {
      window.fbAsyncInit = function() {
        window.FB.init({
          appId      : '177811139482503',
          cookie     : true,
          xfbml      : true,
          version    : 'v15.0'
        });
        window.FB.AppEvents.logPageView();
      };
    },
    async loadFacebookSDK(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },
    async setupGG() {
      // console.log("In setupGG");
      // await this.loadGoogleSDK(document, "script", "google-jssdk");
      await this.initGoogle();
    },
    async initGoogle() {
      var vthis = this;
      window.gapi.load('auth2', () => {
        const auth2 = window.gapi.auth2.init({
          client_id: '50603251905-jn7h49g8indkj18i2ot6kgg2skor32t7.apps.googleusercontent.com',
          cookiepolicy: 'single_host_origin',
          // Request scopes in addition to 'profile' and 'email'
          // scope: 'additional_scope'
        });
        auth2.attachClickHandler(this.$refs.btnGGLogin, {}, googleUser => {
          //// The ID token you need to pass to your backend:
          // var id_token = googleUser.getAuthResponse().id_token;
          // console.log("ID Token: " + id_token);
          // console.log(googleUser);
          // console.log(googleUser.getBasicProfile());
          var profile = googleUser.getBasicProfile();
          // console.log("ID: " + profile.getId());
          // console.log('Full Name: ' + profile.getName());
          // // console.log('Given Name: ' + profile.getGivenName());
          // // console.log('Family Name: ' + profile.getFamilyName());
          // console.log("Image URL: " + profile.getImageUrl());
          // console.log("Email: " + profile.getEmail());

          let formData = {
            "ggid": profile.getId(),
            "username": profile.getName(),
            "email": profile.getEmail() || "",
            "avt": profile.getImageUrl()
          };
          vthis.$store.dispatch('auth/gglogin', formData).then(data => {
            // console.log(data);
            vthis.message = data.msg;
            if(data && data.err >= 0) {
              vthis.successful = true;
              vthis.$router.push(vthis.$route.query.redirect || '/');
            } else {
              vthis.successful = false;
              vthis.loading = false;
            }
          });
        }, error => {
          console.log(error);
        });
      });
    },
    async loadGoogleSDK(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s);
      js.id = id;
      js.src = "https://apis.google.com/js/api:client.js";
      fjs.parentNode.insertBefore(js, fjs);
    },
    logInWithTwitter() {
      // console.log("In logInWithTwitter");
      // console.log(firebase);
      var vthis = this;
      var provider = new firebase.auth.TwitterAuthProvider();
      firebase.auth().signInWithPopup(provider).then(function(result) {
        // console.log(result);
        // // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
        // // You can use these server side with your app's credentials to access the Twitter API.
        // var token = result.credential.accessToken;
        // var secret = result.credential.secret;
        // console.log(token);
        // console.log(secret);
        // The signed-in user info.
        var user = result.user;
        // console.log(user);
        // console.log("user.uid: " + user.uid);
        // console.log("user.email: " + user.email);
        // console.log("user.displayName: " + user.displayName);
        // console.log("user.photoURL: " + user.photoURL);
        let formData = {
          "twid": user.uid,
          "username": user.displayName,
          "email": user.email,
          "avt": user.photoURL
        };
        vthis.$store.dispatch('auth/twlogin', formData).then(data => {
          // console.log(data);
          vthis.message = data.msg;
          if(data && data.err >= 0) {
            vthis.successful = true;
            vthis.$router.push(vthis.$route.query.redirect || '/');
          } else {
            vthis.successful = false;
            vthis.loading = false;
          }
        });
      }).catch(function(error) {
        console.log(error);
        // // Handle Errors here.
        // var errorCode = error.code;
        // var errorMessage = error.message;
        // // The email of the user's account used.
        // var email = error.email;
        // // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;
        // // ...
        // console.log("errorCode: " + errorCode);
        // console.log("errorMessage: " + errorMessage);
        // console.log("email: " + email);
        // console.log("credential: " + credential);
      });
    },
  }
};
</script>
