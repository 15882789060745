import axios from "axios";
import store from "../store";
import config from "../config/config.js";

const API_URL = config.API_URL;
// const API_URL = 'http://localhost:8080/api/v1';

class TopicService {
  getTopicPosts(id, page) {
    return axios.get(API_URL + "/topic?t="+id+"&page="+page, {
      headers: {
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + (store.state.auth.user ? store.state.auth.user.token : '')
      }
    }).then(res => {
      return res.data;
    }).catch(e => {
      console.log(e);
    });
  }
}

export default new TopicService();