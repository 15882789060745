<template>
	<div id="app">
		<!-- Begin Nav ================================================== -->
		<nav class="navbar navbar-expand-lg navbar-light fixed-top mediumnavigation">
			<div class="container">
				<a class="navbar-brand" href="/">
					<img src="./assets/img/vallicon_logo.png" alt="logo">
				</a>
				<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
				</button>

				<div class="collapse navbar-collapse" id="navbarSupportedContent">
					<!-- <ul class="navbar-nav ml-auto">
						<form class="form-inline my-2 my-lg-0">
							<input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
						</form>
					</ul> -->
					<ul class="navbar-nav ml-auto">
						<treeselect
							class="mr-sm-2 tssearch"
							v-model="selectedPost"
							:options="searchPosts"
							:multiple="false"
							:flatten-search-results="true"
							placeholder="Search post..."
							@select="changeSelectPost"
						/>
					</ul>
					
					<ul class="navbar-nav ml-auto">
						<li class="nav-item" :class="(currentPath === '/' || currentPath === '/home') ? 'active' : ''">
							<router-link to="/home" class="nav-link">Home</router-link>
						</li>
						<li class="nav-item" :class="currentPath === '/posts' ? 'active' : ''">
							<router-link to="/posts" class="nav-link">Posts</router-link>
						</li>
						<li v-if="isAdmin" class="nav-item" :class="currentPath === '/edit' ? 'active' : ''">
							<router-link v-if="currentUser" to="/edit" class="nav-link">Edit</router-link>
						</li>
						<li v-if="isAdmin" class="nav-item" :class="currentPath === '/admin' ? 'active' : ''">
							<router-link to="/admin" class="nav-link">Admin</router-link>
						</li>
						<li class="nav-item" :class="currentPath === '/about' ? 'active' : ''">
							<router-link to="/about" class="nav-link">About</router-link>
						</li>
						<!-- <li v-if="!currentUser" class="nav-item" :class="currentPath === '/login' ? 'active' : ''">
							<router-link to="/login" class="nav-link">Login</router-link>
						</li>
						<li v-if="!currentUser" class="nav-item" :class="currentPath === '/register' ? 'active' : ''">
							<router-link to="/register" class="nav-link">Sign Up</router-link>
						</li> -->
						<li v-if="currentUser" class="nav-item dropdown">
							<a class="nav-link nav-avatar" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<!-- <img src="./assets/img/vallicon_avatar2.png" width="37" height="37" class="avatar rounded-circle"> -->
								<!-- <img src="./assets/img/user2.png" width="37" height="37" class="avatar rounded-circle"> -->
								<img v-if="currentUser.avt" :src="currentUser.avt" width="37" height="37" class="avatar rounded-circle">
								<svg v-else class="avatar rounded-circle" version="1.1" id="avatar" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 480 480" style="enable-background:new 0 0 480 480;" xml:space="preserve"><g><g>
									<path d="M240,0C107.664,0,0,107.664,0,240c0,57.96,20.656,111.184,54.992,152.704c0.088,0.12,0.096,0.272,0.192,0.384,c24.792,29.896,55.928,52.816,90.624,67.624c0.4,0.168,0.792,0.352,1.192,0.52c2.808,1.184,5.648,2.28,8.496,3.352
											c1.12,0.424,2.24,0.856,3.376,1.264c2.456,0.88,4.928,1.712,7.416,2.512c1.592,0.512,3.184,1.016,4.792,1.496,c2.2,0.656,4.408,1.288,6.632,1.888c1.952,0.528,3.92,1.016,5.888,1.488c1.992,0.48,3.992,0.96,6,1.384
											c2.24,0.48,4.504,0.904,6.776,1.32c1.824,0.336,3.64,0.688,5.48,0.984c2.52,0.408,5.056,0.728,7.6,1.056,c1.64,0.208,3.272,0.448,4.92,0.624c2.88,0.304,5.784,0.52,8.696,0.72c1.352,0.096,2.696,0.24,4.056,0.312
											c4.248,0.24,8.544,0.368,12.872,0.368s8.624-0.128,12.888-0.352c1.36-0.072,2.704-0.216,4.056-0.312,c2.912-0.208,5.816-0.416,8.696-0.72c1.648-0.176,3.28-0.416,4.92-0.624c2.544-0.328,5.08-0.648,7.6-1.056
											c1.832-0.296,3.656-0.648,5.48-0.984c2.264-0.416,4.528-0.84,6.776-1.32c2.008-0.432,4-0.904,6-1.384,c1.968-0.48,3.936-0.968,5.888-1.488c2.224-0.592,4.432-1.232,6.632-1.888c1.608-0.48,3.2-0.984,4.792-1.496
											c2.488-0.8,4.96-1.632,7.416-2.512c1.128-0.408,2.248-0.84,3.376-1.264c2.856-1.072,5.688-2.176,8.496-3.352,c0.4-0.168,0.792-0.352,1.192-0.52c34.688-14.808,65.832-37.728,90.624-67.624c0.096-0.112,0.104-0.272,0.192-0.384
											C459.344,351.184,480,297.96,480,240C480,107.664,372.336,0,240,0z M337.256,441.76c-0.12,0.056-0.232,0.12-0.352,0.176,c-2.856,1.376-5.76,2.672-8.688,3.936c-0.664,0.28-1.32,0.568-1.984,0.848c-2.56,1.072-5.152,2.088-7.76,3.064
											c-1.088,0.408-2.176,0.808-3.272,1.192c-2.312,0.824-4.632,1.616-6.976,2.368c-1.456,0.464-2.92,0.904-4.384,1.336,c-2.08,0.624-4.168,1.224-6.28,1.784c-1.776,0.472-3.568,0.904-5.36,1.328c-1.88,0.448-3.752,0.904-5.648,1.304
											c-2.072,0.44-4.16,0.816-6.24,1.192c-1.688,0.312-3.368,0.64-5.072,0.912c-2.344,0.368-4.712,0.664-7.072,0.96,c-1.496,0.192-2.984,0.416-4.496,0.576c-2.696,0.288-5.416,0.472-8.128,0.664c-1.208,0.08-2.408,0.216-3.632,0.28
											c-3.96,0.208-7.928,0.32-11.912,0.32s-7.952-0.112-11.904-0.32c-1.216-0.064-2.416-0.192-3.632-0.28,c-2.72-0.184-5.432-0.376-8.128-0.664c-1.512-0.16-3-0.384-4.496-0.576c-2.36-0.296-4.728-0.592-7.072-0.96
											c-1.704-0.272-3.384-0.6-5.072-0.912c-2.088-0.376-4.176-0.76-6.24-1.192c-1.896-0.4-3.776-0.856-5.648-1.304,c-1.792-0.432-3.584-0.856-5.36-1.328c-2.104-0.56-4.2-1.168-6.28-1.784c-1.464-0.432-2.928-0.872-4.384-1.336
											c-2.344-0.752-4.672-1.544-6.976-2.368c-1.096-0.392-2.184-0.792-3.272-1.192c-2.608-0.976-5.2-1.992-7.76-3.064,c-0.664-0.272-1.312-0.56-1.976-0.84c-2.928-1.256-5.832-2.56-8.696-3.936c-0.12-0.056-0.232-0.112-0.352-0.176
											c-27.912-13.504-52.568-32.672-72.576-55.952c15.464-56.944,59.24-102.848,115.56-121.112c1.112,0.68,2.272,1.288,3.416,1.928,c0.672,0.376,1.336,0.776,2.016,1.136c2.384,1.264,4.8,2.448,7.272,3.512c1.896,0.832,3.856,1.536,5.808,2.256
											c0.384,0.136,0.768,0.288,1.152,0.424c10.848,3.84,22.456,6.04,34.6,6.04s23.752-2.2,34.592-6.04,c0.384-0.136,0.768-0.288,1.152-0.424c1.952-0.72,3.912-1.424,5.808-2.256c2.472-1.064,4.888-2.248,7.272-3.512
											c0.68-0.368,1.344-0.76,2.016-1.136c1.144-0.64,2.312-1.248,3.432-1.936c56.32,18.272,100.088,64.176,115.56,121.112,C389.824,409.08,365.168,428.248,337.256,441.76z M152,176c0-48.52,39.48-88,88-88s88,39.48,88,88
											c0,30.864-16.008,58.024-40.128,73.736c-3.152,2.048-6.432,3.88-9.8,5.48c-0.4,0.192-0.792,0.392-1.192,0.576,c-23.168,10.536-50.592,10.536-73.76,0c-0.4-0.184-0.8-0.384-1.192-0.576c-3.376-1.6-6.648-3.432-9.8-5.48
											C168.008,234.024,152,206.864,152,176z M421.832,370.584c-18.136-53.552-59.512-96.832-112.376-117.392,C330.6,234.144,344,206.64,344,176c0-57.344-46.656-104-104-104s-104,46.656-104,104c0,30.64,13.4,58.144,34.552,77.192
											c-52.864,20.568-94.24,63.84-112.376,117.392C31.672,333.792,16,288.704,16,240C16,116.488,116.488,16,240,16s224,100.488,224,224,C464,288.704,448.328,333.792,421.832,370.584z"/></g></g>
								</svg>
							</a>
							<div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
								<router-link to="/profile" class="dropdown-item"><font-awesome-icon icon="user" /><span class="submenu">Profile</span></router-link>
								<router-link v-if="isAdmin" to="/stories" class="dropdown-item"><font-awesome-icon icon="file-word" /><span class="submenu">Stories</span></router-link>
								<router-link to="/bookmark" class="dropdown-item"><font-awesome-icon icon="bookmark" /><span class="submenu">Bookmark</span></router-link>
								<div class="dropdown-divider"></div>
								<a class="dropdown-item" href @click.prevent="logOut"><font-awesome-icon icon="sign-out-alt" /><span class="submenu">Logout</span></a>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</nav>
		<!-- End Nav ================================================== -->

		<router-view />

		<div class="container">
			<div class="footer">
				<div>
					<p class="float-left" style="margin-right: 30px;">Copyright &copy; 2017-{{ new Date().getFullYear() }} Vallicon</p>
					<p class="float-left">
						<a href="/about" style="margin-right: 30px; color: rgba(0, 0, 0, .44);">About</a>
						<a href="/terms" style="margin-right: 30px; color: rgba(0, 0, 0, .44);">Terms</a>
						<a href="/privacy" style="margin-right: 30px; color: rgba(0, 0, 0, .44);">Privacy</a>
					</p>
					<p class="float-right">Thanh Niên Tự Lập, Dân Tộc Tự Tôn, Đất Nước Tự Cường. Sáng Nghiệp - Kiến Quốc</p>
					<div class="clearfix"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import UserService from "./services/user.service";
import PostService from "./services/post.service";
import LinkMixin from "./mixins/link";

export default {
	name: 'App',
	mixins: [LinkMixin],
	data() {
    return {
			selectedPost: null,
      searchPosts: [],
    };
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
		currentPath() {
			return this.$route.path;
		},
		isAdmin() {
			if(this.currentUser && this.currentUser.role) {
				return this.currentUser.role == 'ADqrumXT2ga' || this.currentUser.role == 'RKxqtbyt58d';
			}
			return false;
		},
	},
	created() {
		// checkToken
		this.checkToken();
		// fetch the data when the view is created and the data is already being observed
		// https://router.vuejs.org/guide/advanced/data-fetching.html
    this.fetchData();
  },
	methods: {
		logOut() {
			this.$store.dispatch('auth/logout');
			this.$router.push('/login');
		},
		checkToken() {
			// console.log("In checkToken App");
			if(this.currentUser) {
				UserService.checkToken().then(data => {
					// console.log(data);
					if(data && data.err < 0) {
						this.$store.dispatch('auth/logout');
						window.location.reload();
					}
				});
			}
		},
		fetchData() {
			this.getSearch();
		},
		// linkPost(title, id) {
		// 	return title.toLowerCase().replace(/[:?&]/g,'-').split(' ').join('-') + "-" + id;
		// },
		getSearch() {
			PostService.getSearch().then(data => {
				// console.log(data);
				if(data && data.err >= 0) {
					this.searchPosts = data.posts;
				}
			});
		},
		changeSelectPost(node) {
      // console.log(node);
      // console.log("In changeSelectManaPost: " + node.id);
      if(node.id) {
				window.location.href = '/post/' + this.linkPost(node.label, node.id);
      }
    },
	}
};
</script>
