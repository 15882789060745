import axios from "axios";
import config from "../config/config.js";

const API_URL = config.API_URL;
// const API_URL = 'http://localhost:8080/api/v1';

class AuthService {
	login(user) {
		return axios.post(API_URL + '/login', {
			email: user.email,
			password: user.password
		}).then(res => {
			if(res.data && res.data.err >= 0 && res.data.user) {
				localStorage.setItem('user', JSON.stringify(res.data.user))
			}
			return res.data;
		}).catch(e => {
			console.log(e);
		});
	}
	fblogin(formData) {
		return axios.post(API_URL + '/fblogin', formData).then(res => {
			if(res.data && res.data.err >= 0 && res.data.user) {
				localStorage.setItem('user', JSON.stringify(res.data.user))
			}
			return res.data;
		}).catch(e => {
			console.log(e);
		});
	}
	gglogin(formData) {
		return axios.post(API_URL + '/gglogin', formData).then(res => {
			if(res.data && res.data.err >= 0 && res.data.user) {
				localStorage.setItem('user', JSON.stringify(res.data.user))
			}
			return res.data;
		}).catch(e => {
			console.log(e);
		});
	}
	twlogin(formData) {
		return axios.post(API_URL + '/twlogin', formData).then(res => {
			if(res.data && res.data.err >= 0 && res.data.user) {
				localStorage.setItem('user', JSON.stringify(res.data.user))
			}
			return res.data;
		}).catch(e => {
			console.log(e);
		});
	}
	logout() {
		localStorage.removeItem('user');
	}
	register(user) {
		return axios.post(API_URL + '/signup', {
			username: user.username,
			email: user.email,
			password: user.password,
			repassword: user.repassword
		}).then(res => {
			if(res.data && res.data.err >= 0 && res.data.user) {
				localStorage.setItem('user', JSON.stringify(res.data.user))
			}
			return res.data;
		}).catch(e => {
			console.log(e);
		});
	}
	update(user) {
		localStorage.setItem('user', JSON.stringify(user))
	}
}

export default new AuthService();
