import axios from 'axios';
import store from "../store";
import config from "../config/config.js";

const API_URL = config.API_URL;
// const API_URL = 'http://localhost:8080/api/v1';

class UserService {
  checkToken() {
    return axios.post(API_URL + "/chtoken", {}, {
      headers: {
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + (store.state.auth.user ? store.state.auth.user.token : '')
      }
    }).then(res => {
      return res.data;
    }).catch(e => {
      console.log(e);
    });
  }
  getAuthor(id) {
    return axios.get(API_URL + "/author?u="+id, {
      headers: {
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + (store.state.auth.user ? store.state.auth.user.token : '')
      }
    }).then(res => {
      return res.data;
    }).catch(e => {
      console.log(e);
    });
  }
  changePassword(formData) {
    return axios.post(API_URL + "/user", formData, {
      headers: {
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + (store.state.auth.user ? store.state.auth.user.token : '')
      }
    }).then(res => {
      return res.data;
    }).catch(e => {
      console.log(e);
    });
  }
  updateProfile(formData) {
    return axios.post(API_URL + "/user", formData, {
      headers: {
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + (store.state.auth.user ? store.state.auth.user.token : '')
      }
    }).then(res => {
      return res.data;
    }).catch(e => {
      console.log(e);
    });
  }
  getHome() {
    // console.log("getHome: "+API_URL);
    // console.log(process.env);
    return axios.get(API_URL + "/home", {
      headers: {
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + (store.state.auth.user ? store.state.auth.user.token : '')
      }
    }).then(res => {
      return res.data;
    }).catch(e => {
      console.log(e);
    });
  }
  getBookmark(page) {
    return axios.get(API_URL + "/bookmark?page="+page, {
      headers: {
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + (store.state.auth.user ? store.state.auth.user.token : '')
      }
    }).then(res => {
      return res.data;
    }).catch(e => {
      console.log(e);
    });
  }
  getSocial() {
    return axios.get(API_URL + "/social", {
      headers: {
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + (store.state.auth.user ? store.state.auth.user.token : '')
      }
    }).then(res => {
      return res.data;
    }).catch(e => {
      console.log(e);
    });
  }
}

export default new UserService();
