
<template>
	<div class="container">
		<div class="row authorpage authorprofile">
			<div class="col-lg-4">&nbsp;</div>
			<div class="col-lg-4">
				<img class="rounded-circle" src="../assets/img/vallicon_avatar.png" alt="Avatar" width="140" height="140">
				<h1 class="author-name">Vallicon</h1>
				<span class="author-description">Trang trại Vui Vẻ, tại thung lũng Vallicon</span>
				<div class="sociallinks">
					<a target="_blank" href="https://www.facebook.com/vallic0n/"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook-f' }"/></a> · <a target="_blank" href="https://twitter.com/vallicon"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'twitter' }"/></a>
				</div>
			</div>
			<div class="col-lg-4">&nbsp;</div>
		</div>

		<div class="row">
			<div class="col-md-2 col-xs-12"></div>
			<!-- Begin Post -->
			<div class="col-md-8 col-md-offset-2 col-xs-12">
				<div class="mainheading">
					<h1 class="posttitle">Vallicon được lập ra để làm gì?</h1>
				</div>
				<!-- Begin Featured Image -->
				<img class="featured-image img-fluid" src="../assets/img/8.jpg" alt="vallicon">
				<!-- End Featured Image -->
				<!-- Begin Post Content -->
				<div class="article-post">
					<blockquote>
						Câu trả lời ngắn gọn là để <b>trả ơn đời</b>. <br>
						Dù là cành cây cọng cỏ, vẫn mong tỏa ngát hương thơm cho đời.
						<br/> -- Vallicon --
					</blockquote>
					<p>
						Vallicon ra đời với mục đích cung cấp những kiến thức nền tảng căn bản nhất của kỹ thuật lập trình. 
						Trong đó tập trung trọng tâm vào 2 vấn đề chính là <b>cấu trúc dữ liệu (Data Structure)</b> và 
						<b>giải thuật (Algorithm)</b>. Nếu ai đã học qua 2 môn này, chắc hẳn đều biết đến 1 công thức 
						khá nổi tiếng: <b>Programs = Data Structures + Algrithms</b> cũng là tựa đề của một cuốn sách cùng tên 
						được xuất bản vào năm 1976 của Niklaus Wirth.
					</p>
					<blockquote>
						<b>Programs = Data Structures + Algrithms</b>
						<br/> -- Niklaus Wirth --
					</blockquote>
					<p>
						Dựa trên nền tảng cấu trúc dữ liệu và giải thuật, Vallicon cũng mong muốn lồng ghép các phương pháp 
						tư duy lập trình để giải quyết các vấn đề trong thực tế xây dựng phần mềm phục vụ cho cuộc sống hằng ngày.
					</p>
					<p>
						Việc nắm vững cấu trúc dữ liệu và giải thuật chỉ là điều kiện cần để các bạn xây dựng một chương trình 
						nhằm giải quyết một bài toán cụ thể nào đó, để có thể xây dựng một hệ thống phức tạp thì bản thân mỗi 
						kỹ sư phần mềm cần phải trang bị thêm những kiến thức về <b>ngôn ngữ lập trình (Programming), kỹ thuật 
						lõi (Framework), cơ sở dữ liệu (Database), giao thức (Protocol), hạ tầng mạng (Network), nền tảng (Platform), 
						kiến trúc phần mềm (Software Architect), kiến trúc hệ thống (System Architect), bảo mật (Security), 
						mật mã (Cryptography)...</b>, một loạt các kiến thức chuyên sâu, chuyên ngành như: <b>khoa học máy tính 
						(Computer Science), trí tuệ nhân tạo (Artificial Intelligence - AI), hệ thống thông tin (Information System), 
						khoa học dữ liệu (Data Science), thị giác máy tính (Computer Vision), thống kê máy tính 
						(Computer Statistics)</b>... Như đã đề cập ở trên, mục tiêu trước mắt của Vallicon là làm tốt 2 mảng 
						cấu trúc dữ liệu và giải thuật, sau đó sẽ tính tiếp, mặc dù khát vọng thì có thừa nhưng sức lực thì có hạn.
					</p>
					<p>
						Vậy tại sao lại chọn cấu trúc dữ liệu và giải thuật mà không phải là các vấn đề khá hót hiện nay như: 
						AI, Machine Learning, Big Data, Deep Learning, NLP, Robotics, Cryptocurrency, Blockchain... Đơn giản 
						là vì Vallicon muốn tập trung xây dựng nền móng vững chắc cho các bạn trẻ Việt Nam, trong đó đối tượng 
						hướng tới là học sinh, sinh viên. Sau khi có nền móng vững chắc rồi, các bạn ấy tự biết xây dựng cái gì 
						trên đó mà không lo sợ bão gió gì nữa. Mặt khác, sau bao năm lăn lộn với nghề, Vallicon nhận ra rằng: 
						những thứ lao sao trên mặt nước như: ngôn ngữ lập trình, các Framework, các thư viện, hoặc các công nghệ 
						mới... hôm nay có thể hót, nhưng biết đâu ngày mai đã lụi tàn, lạc hậu. Duy chỉ có cấu trúc dữ liệu và 
						giải thuật là 2 thứ khá ổn định theo thời gian. Nó chỉ mất đi khi nào cái máy tính cuối cùng biến mất 
						trên toàn thế giới.
					</p>
					<p>
						Vallicon ra đời với sứ mạng hỗ trợ kiến thức cho các bạn trẻ Việt Nam đam mê lập trình, hoặc có 
						định hướng nghề nghiệp cá nhân theo con đường công nghệ thông tin hoặc các bạn đang kiếm sống 
						bằng nghề này, có 1 nơi để trao đổi thảo luận các vấn đề xoay quanh chủ đề <b>cấu trúc dữ liệu 
						và giải thuật</b>.
					</p>
					<!-- <p>
						Vallicon ra đời với sứ mạng hỗ trợ kiến thức cho các bạn trẻ Việt Nam đam mê lập trình, hoặc có 
						định hướng nghề nghiệp cá nhân theo con đường công nghệ thông tin hoặc các bạn đang kiếm sống 
						bằng nghề này, có 1 nơi để trao đổi thảo luận các vấn đề xoay quanh chủ đề <b>cấu trúc dữ liệu 
						và giải thuật</b>. Với sứ mạng như vậy, cho nên nội dung của các bài viết đều được thể hiện 
						bằng Tiếng Việt, vì nó chỉ dành cho người Việt mà thôi. Điều này cũng xuất phát từ tinh thần dân tộc 
						cao độ của Vallicon, <b>lợi ích quốc gia dân tộc là trên hết, cái gì có lợi cho dân tộc phải hết sức làm, 
						cái gì có hại phải hết sức tránh</b>. Đương nhiên, tinh thần dân tộc này phải dựa trên nền tảng nhân văn, 
						không xâm phạm đến lợi ích của ai. Hoàn toàn không phải vì lợi ích mà bất chấp tất cả, phải là giải pháp 
						Win-Win đôi bên cùng có lợi thì mới bền vững. Riêng trường hợp này, cho phép Vallicon thể hiện sự ích kỷ 
						đáng yêu của mình: Viết Tiếng Việt, dành cho người Việt.
					</p>
					<blockquote>
						<b>
							Lợi ích quốc gia dân tộc là trên hết. Cái gì có lợi cho dân tộc phải hết sức làm, cái gì có hại 
							phải hết sức tránh.
						</b>
						<br/> -- Vallicon --
					</blockquote>
					<p>
						Một ý khác nữa, trong việc thể hiện nội dung bằng Tiếng Việt, cũng là xuất phát từ sự khan hiếm nguồi 
						tài liệu tham khảo CNTT trực tuyến bằng Tiếng Việt. Mặc dù, dân trong nghề việc đọc hiểu tài liệu 
						Tiếng Anh là bắt buộc, nhưng thiết nghĩ nếu có 1 nguồi tài liệu tương ứng bằng Tiếng Việt vẫn tốt hơn chứ, 
						đúng không? Dù sao đọc tài liệu bằng tiếng mẹ đẻ cũng đỡ nóng não hơn ngoại ngữ. Mặt khác, ta có thể đọc 
						lướt nhanh để nắm ý, từ đó tiết kiệm được thời gian tìm hiểu. Nhất là đối với các em học sinh sinh viên 
						mới vào nghề. Việc đọc tài liệu Tiếng Việt để nắm bắt các ý niệm ban đầu là hết sức quan trọng.
					</p> -->
					<p>
						Chúng ta đang sống trong một thế giới phẳng, mà công nghệ thông tin là một nhân tố làm phẳng thế giới. 
						Cho nên nghề CNTT là một nghề phẳng, do đó người làm nghề này phải chịu sự cạnh tranh khốc liệt. 
						Nếu xét về cá nhân thì không còn cách nào khác, mỗi người phải phấn đấu trở thành công dân toàn cầu, 
						còn xét theo nghề thì phải trở thành công nhân toàn cầu. Vì vậy, thật tội cho những ai chót theo nghề 
						CNTT mà có tư duy an phận, ngừng phấn đấu, ngừng học tập, tâm lý tự mãn, hoặc tệ hơn là co ro vào vỏ ốc 
						an toàn... về mặt nguyên lý, cạnh tranh nó sẽ bóp chết những cá nhân có tư tưởng như vậy, thất nghiệp 
						là chuyện đương nhiên nếu họ không ý thức được điều này.
					</p>
					<blockquote>
						<b>Thanh Niên Tự Lập, Dân Tộc Tự Tôn, Đất Nước Tự Cường. Sáng Nghiệp - Kiến Quốc.</b>
						<br/> -- Vallicon --
					</blockquote>
					<p>
						Hơn nữa, thế giới đang chuyển mình sang làn sóng của cuộc cách mạng công nghiệp 4.0, khoa học công nghệ 
						phát triển nhanh như vũ bão, cả về quy mô lẫn tốc độ, dựa trên 3 trụ cột chính: <b>vật lý, kỹ thuật số 
						và sinh học</b>. Do đó, công nghệ mới sinh ra liên tục và cũng sẽ chết liên tục, vòng đời sản phẩm, 
						công nghệ ngày càng ngắn, cho nên các thế hệ điện thoại ra đời liên tục. Nhưng nó cũng sẽ giúp cho 
						các cá nhân, doanh nghiệp, quốc gia thăng hoa nếu lướt được trên làn sóng đó. Cuộc cách mạng công nghiệp 4.0 
						này dựa trên một thứ tài nguyên rất đặc biệt, đó là chất xám, mà đây lại là thứ mà người VN có tố chất 
						khá tốt, vấn đề là đào tạo và sử dụng như thế nào mà thôi. Cho nên hơn ai hết, thế hệ trẻ phải là lực lượng 
						tham gia cuộc chơi này, đạt trình độ toàn cầu và hành nghề toàn cầu, biển rộng trời cao tha hồ vẫy vùng. 
						CNTT được xem là hạ tầng của hạ tầng, len lõi vào mọi ngành khoa học, mọi ngành nghề, mọi ngóc ngánh của 
						xã hội. Do đó, CNTT mở ra một nhu cầu rất lớn, rất đa dạng về nhân lực. Cho nên, Vallicon ra đời là để 
						nâng đỡ những bước chân đầu tiên chập chững vào nghề của các bạn trẻ đầy đam mê và nhiệt huyết, góp phần
						chấp cánh cho những ước mơ đẹp. Đặc biệt là các bạn có hoàn cảnh khó khăn thì đây chính là một con đường 
						để các bạn thoát nghèo bền vững, sống một cuộc sống giàu sang, hào sảng, phong lưu tuyệt đỉnh.
					</p>
					<blockquote>
						<b>Sinh ra trong nghèo khó không phải lỗi ở bạn, nhưng chết trong nghèo khổ thì đó là lỗi của bạn.</b>
						<br/> -- Vallicon --
					</blockquote>
					<!-- <p>
						Lúc trước, tôi cũng muốn trở thành giảng viên đại học. Nhưng ngoặt một nỗi con nhà nghèo, nên ngay khi 
						tốt nghiệp, việc đầu tiên tôi nghĩ tới là đi làm kiếm tiền trả nợ và phụ giúp gia đình. Bây giờ 
						nghĩ lại, nếu tôi làm giảng viên, mỗi năm cùng lắm tôi cũng chỉ giúp được cho 300-500 sinh viên CNTT 
						của cùng 1 trường. Trong khi đó, nếu đưa kiến thức lên Vallicon, không chỉ cung cấp kiến thức cho 
						nhiều người hơn ở khắp nơi, mà còn phục vụ liên tục 24/7 trong nhiều năm liền.
					</p>
					<blockquote>
						<b>Đừng bao giờ nhắc đến lý tưởng với cái bụng đói meo và con đang khóc.</b>
						<br/> -- Vallicon --
					</blockquote> -->
					<p>
						Vallicon mong muốn trở thành một không gian mở và thuận tiện cho hết thảy bạn đọc có thể trao đổi, 
						thảo luận, đóng góp, cùng nhau phát triển, cùng nhau tiến bộ. Để thế hệ trẻ Việt Nam ngày càng 
						giỏi giang, hiên ngang sánh vai cùng với bè bạn khắp năm châu.
					</p>
					<blockquote>
						<b>Cứ thêm 1 người Việt Nam giỏi giang thì cả nước Việt Nam giỏi giang.</b><br/>
						<b>Cứ thêm 1 người Việt Nam giàu sang thì cả nước Việt Nam giàu sang.</b><br/> -- Vallicon --
					</blockquote>
					<p>
						Vallicon là nơi để tôi có thể ôn lại những kiến thức đã biết và tìm hiểu thêm những cấu trúc dữ liệu 
						và giải thuật mới. Quá trình viết cũng là quá trình học, đó cũng là cách tôi tự đào tạo tôi. Vallicon 
						là nơi tôi ghi chú, để nhở khi tôi quên, tôi có thể nghía qua Vallicon để nhanh chóng nắm lại những ý chính.
					</p>
					<blockquote>
						<b>"What wrong, not Who wrong"</b>
					</blockquote>
					<p>
						Vallicon hoạt động trên tinh thần chia sẻ cởi mở. Mọi ý kiến thảo luận hay đóng góp, các bạn có thể 
						để lại bình luận của mình một cách văn minh lịch sự. Mọi lời lẽ thô tục, xúc phạm người khác, hoặc 
						tấn công chỉ trích cá nhân... đều bị xóa bỏ mà không cần báo trước. Thay vào đó, chúng ta nên tranh luận và 
						thể hiện việc không đồng ý với tư tưởng này hoặc ý kiến kia, tức là ta nên dùng các cụm từ mang tính 
						tranh luận như: <b>"Tôi không đồng ý với ý kiến ABC..", "Tôi không đồng ý với ý tưởng DEF...", "Tôi không 
						đồng ý với cách làm HIJ...", "Tôi không đồng ý với cách nghĩ KLM...", "Tôi không đồng ý với cách lập luận NOP...",
						"Tôi không thích cái thuật toán XYZ..."</b>, ... Tóm lại, chúng ta tranh luận cái gì đúng, cái gì sai, 
						không phải là ai đúng ai sai. <b>"What wrong, not Who wrong"</b>.
					</p>
					<blockquote>
						<b>Một kiếp nhân sinh mấy vạn ngày</b><br/>
						<b>Chỉ là một thoáng thời gian bay</b><br/>
						<b>Nghiêng vai đã thấy phai màu tóc</b><br/>
						<b>Đời là ảo mộng mấy ai hay.</b> <br/>-- Khuyết danh --
					</blockquote>
					<p>
						Cuối cùng, Vallicon là một lãng tử thích mơ mộng viễn vông, chỉ mong sống 1 cuộc sống thanh tao, 
						hằng ngày rong ruổi vui thú với non sông, phiêu bạt giang hồ, phong lưu khoáng đạt, tiêu diêu tự tại.
					</p>
					<p>VietNam, 22h10 24/11/2017.</p>
					<p><b>-- Vallicon --</b></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'About',
}
</script>