import * as firebase from "firebase/app";
import 'firebase/auth';
// import "firebase/firestore";

// firebase init - add your own config here
var firebaseConfig = {
  apiKey: "AIzaSyCI-W4TMS_8TBtZwgVu80gV12zzQflzMd4",
  authDomain: "vallicon-f9d41.firebaseapp.com",
  databaseURL: "https://vallicon-f9d41.firebaseio.com",
  projectId: "vallicon-f9d41",
  storageBucket: "vallicon-f9d41.appspot.com",
  messagingSenderId: "178838753503",
  appId: "1:178838753503:web:e10149334f2b1ddc48d96a",
  measurementId: "G-SWM44SVJWS"
};
firebase.initializeApp(firebaseConfig);

export default firebase;

// utils
// const auth = firebase.auth();

// // export utils/refs
// export default {
//   fbauth: firebase,
//   login() {
//     console.log("firebase login");

//     var provider = new firebase.auth.TwitterAuthProvider();
//     firebase.auth().signInWithPopup(provider).then(function(result) {
//       console.log(result);
//       // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
//       // You can use these server side with your app's credentials to access the Twitter API.
//       var token = result.credential.accessToken;
//       var secret = result.credential.secret;
//       console.log(token);
//       console.log(secret);
//       // The signed-in user info.
//       var user = result.user;
//       console.log(user);
//       console.log("user.uid: " + user.uid);
//       console.log("user.email: " + user.email);
//       console.log("user.displayName: " + user.displayName);
//       console.log("user.photoURL: " + user.photoURL);
//       // ...
//       let formData = {
//         "twid": user.uid,
//         "username": user.displayName,
//         "email": user.email,
//         "avt": user.photoURL
//       };
//       return formData;
//     }).catch(function(error) {
//       // Handle Errors here.
//       var errorCode = error.code;
//       var errorMessage = error.message;
//       // The email of the user's account used.
//       var email = error.email;
//       // The firebase.auth.AuthCredential type that was used.
//       var credential = error.credential;
//       // ...
//       console.log("errorCode: " + errorCode);
//       console.log("errorMessage: " + errorMessage);
//       console.log("email: " + email);
//       console.log("credential: " + credential);
//     });
//   },
//   logout() {
//     console.log("firebase logout");

//     firebase.auth().signOut().then(function() {
//       // Sign-out successful.
//       console.log("firebase logout successful");
//     }).catch(function(error) {
//       // An error happened.
//       console.log("firebase logout error: " + error);
//     });
//   },
// };