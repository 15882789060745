import AuthService from "../services/auth.service";

const userStore = JSON.parse(localStorage.getItem('user'));
const initialState = userStore ? {status: {loggedIn: true}, user: userStore} : {status: {loggedIn: false}, user: null};

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({commit}, user) {
      return AuthService.login(user).then(data => {
        if(data && data.err >= 0) {
          commit('loginSuccess', data.user);
        } else {
          commit('loginFailure');
        }
        return Promise.resolve(data);
      }, error => {
        commit('loginFailure');
        return Promise.reject(error);
      });
    },
    fblogin({commit}, formData) {
      return AuthService.fblogin(formData).then(data => {
        if(data && data.err >= 0) {
          commit('loginSuccess', data.user);
        } else {
          commit('loginFailure');
        }
        return Promise.resolve(data);
      }, error => {
        commit('loginFailure');
        return Promise.reject(error);
      });
    },
    gglogin({commit}, formData) {
      return AuthService.gglogin(formData).then(data => {
        if(data && data.err >= 0) {
          commit('loginSuccess', data.user);
        } else {
          commit('loginFailure');
        }
        return Promise.resolve(data);
      }, error => {
        commit('loginFailure');
        return Promise.reject(error);
      });
    },
    twlogin({commit}, formData) {
      return AuthService.twlogin(formData).then(data => {
        if(data && data.err >= 0) {
          commit('loginSuccess', data.user);
        } else {
          commit('loginFailure');
        }
        return Promise.resolve(data);
      }, error => {
        commit('loginFailure');
        return Promise.reject(error);
      });
    },
    logout({commit}) {
      AuthService.logout();
      commit('logout');
    },
    register({commit}, user) {
      return AuthService.register(user).then(data => {
        if(data && data.err >= 0) {
          commit('registerSuccess', data.user);
        } else {
          commit('registerFailure', null);
        }
        return Promise.resolve(data);
      }, error => {
        commit('registerFailure', null);
        return Promise.reject(error);
      });
    },
    update({commit}, user) {
      AuthService.update(user);
      commit('update', user);
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    update(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
  }
};

