
<template>
	<div>
		<div v-if="isComplete && post.id" class="container">
			<div class="row">
				<!-- Begin Fixed Left Share -->
				<div class="col-md-2 col-xs-12">
					<div v-if="post && post.status !== 'Draft'" class="share">
						<p>Share</p>
						<ul>
							<li>
								<!-- <div class="fb-share-button" data-href="https://developers.facebook.com/docs/plugins/" data-layout="box_count" data-size="small"></div> -->
								<!-- <a target="_blank" :href="'https://www.facebook.com/sharer/sharer.php?u='+encodeURIComponent('https://vallicon.com/post/'+linkPost(post.title, post.id))" title="facebook">
									<svg class="svgIcon-use" width="29" height="29" viewbox="0 0 29 29"><path d="M16.39 23.61v-5.808h1.846a.55.55 0 0 0 .546-.48l.36-2.797a.551.551 0 0 0-.547-.62H16.39V12.67c0-.67.12-.813.828-.813h1.474a.55.55 0 0 0 .55-.55V8.803a.55.55 0 0 0-.477-.545c-.436-.06-1.36-.116-2.22-.116-2.5 0-4.13 1.62-4.13 4.248v1.513H10.56a.551.551 0 0 0-.55.55v2.797c0 .304.248.55.55.55h1.855v5.76c-4.172-.96-7.215-4.7-7.215-9.1 0-5.17 4.17-9.36 9.31-9.36 5.14 0 9.31 4.19 9.31 9.36 0 4.48-3.155 8.27-7.43 9.15M14.51 4C8.76 4 4.1 8.684 4.1 14.46c0 5.162 3.75 9.523 8.778 10.32a.55.55 0 0 0 .637-.543v-6.985a.551.551 0 0 0-.55-.55H11.11v-1.697h1.855a.55.55 0 0 0 .55-.55v-2.063c0-2.02 1.136-3.148 3.03-3.148.567 0 1.156.027 1.597.06v1.453h-.924c-1.363 0-1.93.675-1.93 1.912v1.78c0 .3.247.55.55.55h2.132l-.218 1.69H15.84c-.305 0-.55.24-.55.55v7.02c0 .33.293.59.623.54 5.135-.7 9.007-5.11 9.007-10.36C24.92 8.68 20.26 4 14.51 4"></path></svg>
								</a> -->
								<a href @click.prevent="shareFB" title="facebook">
									<svg class="svgIcon-use" width="29" height="29" viewbox="0 0 29 29"><path d="M16.39 23.61v-5.808h1.846a.55.55 0 0 0 .546-.48l.36-2.797a.551.551 0 0 0-.547-.62H16.39V12.67c0-.67.12-.813.828-.813h1.474a.55.55 0 0 0 .55-.55V8.803a.55.55 0 0 0-.477-.545c-.436-.06-1.36-.116-2.22-.116-2.5 0-4.13 1.62-4.13 4.248v1.513H10.56a.551.551 0 0 0-.55.55v2.797c0 .304.248.55.55.55h1.855v5.76c-4.172-.96-7.215-4.7-7.215-9.1 0-5.17 4.17-9.36 9.31-9.36 5.14 0 9.31 4.19 9.31 9.36 0 4.48-3.155 8.27-7.43 9.15M14.51 4C8.76 4 4.1 8.684 4.1 14.46c0 5.162 3.75 9.523 8.778 10.32a.55.55 0 0 0 .637-.543v-6.985a.551.551 0 0 0-.55-.55H11.11v-1.697h1.855a.55.55 0 0 0 .55-.55v-2.063c0-2.02 1.136-3.148 3.03-3.148.567 0 1.156.027 1.597.06v1.453h-.924c-1.363 0-1.93.675-1.93 1.912v1.78c0 .3.247.55.55.55h2.132l-.218 1.69H15.84c-.305 0-.55.24-.55.55v7.02c0 .33.293.59.623.54 5.135-.7 9.007-5.11 9.007-10.36C24.92 8.68 20.26 4 14.51 4"></path></svg>
								</a>
							</li>
						</ul>
						<ul>
							<li>
								<a target="_blank" :href="linkTW" title="twitter">
									<svg class="svgIcon-use" width="29" height="29" viewbox="0 0 29 29"><path d="M21.967 11.8c.018 5.93-4.607 11.18-11.177 11.18-2.172 0-4.25-.62-6.047-1.76l-.268.422-.038.5.186.013.168.012c.3.02.44.032.6.046 2.06-.026 3.95-.686 5.49-1.86l1.12-.85-1.4-.048c-1.57-.055-2.92-1.08-3.36-2.51l-.48.146-.05.5c.22.03.48.05.75.08.48-.02.87-.07 1.25-.15l2.33-.49-2.32-.49c-1.68-.35-2.91-1.83-2.91-3.55 0-.05 0-.01-.01.03l-.49-.1-.25.44c.63.36 1.35.57 2.07.58l1.7.04L7.4 13c-.978-.662-1.59-1.79-1.618-3.047a4.08 4.08 0 0 1 .524-1.8l-.825.07a12.188 12.188 0 0 0 8.81 4.515l.59.033-.06-.59v-.02c-.05-.43-.06-.63-.06-.87a3.617 3.617 0 0 1 6.27-2.45l.2.21.28-.06c1.01-.22 1.94-.59 2.73-1.09l-.75-.56c-.1.36-.04.89.12 1.36.23.68.58 1.13 1.17.85l-.21-.45-.42-.27c-.52.8-1.17 1.48-1.92 2L22 11l.016.28c.013.2.014.35 0 .52v.04zm.998.038c.018-.22.017-.417 0-.66l-.498.034.284.41a8.183 8.183 0 0 0 2.2-2.267l.97-1.48-1.6.755c.17-.08.3-.02.34.03a.914.914 0 0 1-.13-.292c-.1-.297-.13-.64-.1-.766l.36-1.254-1.1.695c-.69.438-1.51.764-2.41.963l.48.15a4.574 4.574 0 0 0-3.38-1.484 4.616 4.616 0 0 0-4.61 4.613c0 .29.02.51.08.984l.01.02.5-.06.03-.5c-3.17-.18-6.1-1.7-8.08-4.15l-.48-.56-.36.64c-.39.69-.62 1.48-.65 2.28.04 1.61.81 3.04 2.06 3.88l.3-.92c-.55-.02-1.11-.17-1.6-.45l-.59-.34-.14.67c-.02.08-.02.16 0 .24-.01 2.12 1.55 4.01 3.69 4.46l.1-.49-.1-.49c-.33.07-.67.12-1.03.14-.18-.02-.43-.05-.64-.07l-.76-.09.23.73c.57 1.84 2.29 3.14 4.28 3.21l-.28-.89a8.252 8.252 0 0 1-4.85 1.66c-.12-.01-.26-.02-.56-.05l-.17-.01-.18-.01L2.53 21l1.694 1.07a12.233 12.233 0 0 0 6.58 1.917c7.156 0 12.2-5.73 12.18-12.18l-.002.04z"></path></svg>
								</a>
							</li>
						</ul>
						<div v-if="currentUser" class="sep"></div>
						<ul v-if="currentUser">
							<li>
								<a href @click.prevent="likePost" :title="social.like ? 'Unlike' : 'Like'">
										<!-- https://css-tricks.com/hearts-in-html-and-css/ -->
										<!-- https://medium.com/@webalys/die-png-die-how-to-use-vector-icons-in-your-apps-d884c9c63e93 -->
										<svg class="heart" :class="social.like ? 'heart-on' : ''" viewBox="0 0 29 29"><path d="M20.5,9.5,c-1.955,0,-3.83,1.268,-4.5,3,c-0.67,-1.732,-2.547,-3,-4.5,-3,C8.957,9.5,7,11.432,7,14,c0,3.53,3.793,6.257,9,11.5,c5.207,-5.242,9,-7.97,9,-11.5,C25,11.432,23.043,9.5,20.5,9.5z"/></svg>
									<p>{{post.like}}</p>
								</a>
							</li>
						</ul>
						<ul v-if="currentUser">
							<li>
								<span @click="bookmarkPost" :title="social.bookmark ? 'Unbookmark' : 'Bookmark'">
									<span v-if="social.bookmark" class="bookmark bookmark-activeState">
										<svg class="svgIcon-use" width="29" height="29" viewBox="0 0 29 29">
											<path d="M19.385 4h-9.77A2.623 2.623 0 0 0 7 6.615V23.01a1.022 1.022 0 0 0 1.595.847l5.905-4.004 5.905 4.004A1.022 1.022 0 0 0 22 23.011V6.62A2.625 2.625 0 0 0 19.385 4z" fill-rule="evenodd"></path>
										</svg>
									</span>
									<span v-else class="bookmark bookmark-defaultState">
										<svg class="svgIcon-use" width="29" height="29" viewBox="0 0 29 29">
											<path d="M19.385 4h-9.77A2.623 2.623 0 0 0 7 6.615V23.01a1.022 1.022 0 0 0 1.595.847l5.905-4.004 5.905 4.004A1.022 1.022 0 0 0 22 23.011V6.62A2.625 2.625 0 0 0 19.385 4zM21 23l-5.91-3.955-.148-.107a.751.751 0 0 0-.884 0l-.147.107L8 23V6.615C8 5.725 8.725 5 9.615 5h9.77C20.275 5 21 5.725 21 6.615V23z" fill-rule="evenodd"></path>
										</svg>
									</span>
								</span>
							</li>
						</ul>
					</div>
				</div>
				<!-- End Fixed Left Share -->

				<!-- Begin Post -->
				<div class="col-md-8 col-md-offset-2 col-xs-12">
					<div class="mainheading">
						<!-- Begin Top Meta -->
						<div v-if="post.author" class="row post-top-meta">
							<div class="col-md-2" style="text-align: center;">
								<a :href="'/author/'+post.author.id">
									<img v-if="post.author.avt" :src="post.author.avt" class="author-thumb" alt="avatar" style="margin: 0px; float: none;">
									<svg v-else class="author-thumb" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 480 480" style="enable-background:new 0 0 480 480; float: none; margin-right: auto;" xml:space="preserve"><g><g>
										<path d="M240,0C107.664,0,0,107.664,0,240c0,57.96,20.656,111.184,54.992,152.704c0.088,0.12,0.096,0.272,0.192,0.384,c24.792,29.896,55.928,52.816,90.624,67.624c0.4,0.168,0.792,0.352,1.192,0.52c2.808,1.184,5.648,2.28,8.496,3.352
												c1.12,0.424,2.24,0.856,3.376,1.264c2.456,0.88,4.928,1.712,7.416,2.512c1.592,0.512,3.184,1.016,4.792,1.496,c2.2,0.656,4.408,1.288,6.632,1.888c1.952,0.528,3.92,1.016,5.888,1.488c1.992,0.48,3.992,0.96,6,1.384
												c2.24,0.48,4.504,0.904,6.776,1.32c1.824,0.336,3.64,0.688,5.48,0.984c2.52,0.408,5.056,0.728,7.6,1.056,c1.64,0.208,3.272,0.448,4.92,0.624c2.88,0.304,5.784,0.52,8.696,0.72c1.352,0.096,2.696,0.24,4.056,0.312
												c4.248,0.24,8.544,0.368,12.872,0.368s8.624-0.128,12.888-0.352c1.36-0.072,2.704-0.216,4.056-0.312,c2.912-0.208,5.816-0.416,8.696-0.72c1.648-0.176,3.28-0.416,4.92-0.624c2.544-0.328,5.08-0.648,7.6-1.056
												c1.832-0.296,3.656-0.648,5.48-0.984c2.264-0.416,4.528-0.84,6.776-1.32c2.008-0.432,4-0.904,6-1.384,c1.968-0.48,3.936-0.968,5.888-1.488c2.224-0.592,4.432-1.232,6.632-1.888c1.608-0.48,3.2-0.984,4.792-1.496
												c2.488-0.8,4.96-1.632,7.416-2.512c1.128-0.408,2.248-0.84,3.376-1.264c2.856-1.072,5.688-2.176,8.496-3.352,c0.4-0.168,0.792-0.352,1.192-0.52c34.688-14.808,65.832-37.728,90.624-67.624c0.096-0.112,0.104-0.272,0.192-0.384
												C459.344,351.184,480,297.96,480,240C480,107.664,372.336,0,240,0z M337.256,441.76c-0.12,0.056-0.232,0.12-0.352,0.176,c-2.856,1.376-5.76,2.672-8.688,3.936c-0.664,0.28-1.32,0.568-1.984,0.848c-2.56,1.072-5.152,2.088-7.76,3.064
												c-1.088,0.408-2.176,0.808-3.272,1.192c-2.312,0.824-4.632,1.616-6.976,2.368c-1.456,0.464-2.92,0.904-4.384,1.336,c-2.08,0.624-4.168,1.224-6.28,1.784c-1.776,0.472-3.568,0.904-5.36,1.328c-1.88,0.448-3.752,0.904-5.648,1.304
												c-2.072,0.44-4.16,0.816-6.24,1.192c-1.688,0.312-3.368,0.64-5.072,0.912c-2.344,0.368-4.712,0.664-7.072,0.96,c-1.496,0.192-2.984,0.416-4.496,0.576c-2.696,0.288-5.416,0.472-8.128,0.664c-1.208,0.08-2.408,0.216-3.632,0.28
												c-3.96,0.208-7.928,0.32-11.912,0.32s-7.952-0.112-11.904-0.32c-1.216-0.064-2.416-0.192-3.632-0.28,c-2.72-0.184-5.432-0.376-8.128-0.664c-1.512-0.16-3-0.384-4.496-0.576c-2.36-0.296-4.728-0.592-7.072-0.96
												c-1.704-0.272-3.384-0.6-5.072-0.912c-2.088-0.376-4.176-0.76-6.24-1.192c-1.896-0.4-3.776-0.856-5.648-1.304,c-1.792-0.432-3.584-0.856-5.36-1.328c-2.104-0.56-4.2-1.168-6.28-1.784c-1.464-0.432-2.928-0.872-4.384-1.336
												c-2.344-0.752-4.672-1.544-6.976-2.368c-1.096-0.392-2.184-0.792-3.272-1.192c-2.608-0.976-5.2-1.992-7.76-3.064,c-0.664-0.272-1.312-0.56-1.976-0.84c-2.928-1.256-5.832-2.56-8.696-3.936c-0.12-0.056-0.232-0.112-0.352-0.176
												c-27.912-13.504-52.568-32.672-72.576-55.952c15.464-56.944,59.24-102.848,115.56-121.112c1.112,0.68,2.272,1.288,3.416,1.928,c0.672,0.376,1.336,0.776,2.016,1.136c2.384,1.264,4.8,2.448,7.272,3.512c1.896,0.832,3.856,1.536,5.808,2.256
												c0.384,0.136,0.768,0.288,1.152,0.424c10.848,3.84,22.456,6.04,34.6,6.04s23.752-2.2,34.592-6.04,c0.384-0.136,0.768-0.288,1.152-0.424c1.952-0.72,3.912-1.424,5.808-2.256c2.472-1.064,4.888-2.248,7.272-3.512
												c0.68-0.368,1.344-0.76,2.016-1.136c1.144-0.64,2.312-1.248,3.432-1.936c56.32,18.272,100.088,64.176,115.56,121.112,C389.824,409.08,365.168,428.248,337.256,441.76z M152,176c0-48.52,39.48-88,88-88s88,39.48,88,88
												c0,30.864-16.008,58.024-40.128,73.736c-3.152,2.048-6.432,3.88-9.8,5.48c-0.4,0.192-0.792,0.392-1.192,0.576,c-23.168,10.536-50.592,10.536-73.76,0c-0.4-0.184-0.8-0.384-1.192-0.576c-3.376-1.6-6.648-3.432-9.8-5.48
												C168.008,234.024,152,206.864,152,176z M421.832,370.584c-18.136-53.552-59.512-96.832-112.376-117.392,C330.6,234.144,344,206.64,344,176c0-57.344-46.656-104-104-104s-104,46.656-104,104c0,30.64,13.4,58.144,34.552,77.192
												c-52.864,20.568-94.24,63.84-112.376,117.392C31.672,333.792,16,288.704,16,240C16,116.488,116.488,16,240,16s224,100.488,224,224,C464,288.704,448.328,333.792,421.832,370.584z"/></g></g>
									</svg>
								</a>
							</div>
							<div class="col-md-10">
								<a class="link-dark" :href="'/author/'+post.author.id">{{post.author.name}}</a><a v-if="currentUser && post.author.id !== currentUser.id" href @click.prevent="followUser(post.author.id)" :title="social.follow ? 'Unfollow' : 'Follow'" class="btn follow">{{social.follow ? 'Following' : 'Follow'}}</a>
								<p class="author-description">{{post.author.bio}}</p>
								<span class="post-date">{{post.create}}</span> · <span class="post-read">{{post.time}} min read</span>
							</div>
						</div>
						<!-- End Top Menta -->
						<h1 class="posttitle">{{post.title}}</h1>
					</div>

					<!-- Begin Featured Image -->
					<img v-if="post.coverUrl" class="featured-image img-fluid" :src="post.coverUrl" alt="post cover">
					<!-- End Featured Image -->

					<!-- Begin Post Content -->
					<div class="article-post" v-html="post.content"></div>
					<!-- End Post Content -->

					<!-- Begin Tags -->
					<div class="after-post-tags">
						<ul class="tags">
							<li v-for="tag in post.tags" :key="tag.id"><a :href="'/tag/'+linkTag(tag.name, tag.id)">{{tag.name}}</a></li>
						</ul>
					</div>
					<!-- End Tags -->
					<!-- Begin FB Like && Share -->
					<hr>
					<div class="col-xs-12 d-none d-md-block">
						<div class="fb-like" :data-href="'https://vallicon.com/post/'+post.id" data-width="700" data-layout="standard" data-action="like" data-size="small" data-show-faces="true" data-share="true"></div>
					</div>
					<div class="col-xs-12 d-md-none">
						<div class="fb-like" :data-href="'https://vallicon.com/post/'+post.id" data-width="300" data-layout="standard" data-action="like" data-size="small" data-show-faces="true" data-share="true"></div>
					</div>
					<!-- End FB Like && Share -->
					<!-- Begin FB Comments -->
					<hr>
					<div class="col-xs-12">
						<div class="fb-comments" :data-href="'https://vallicon.com/post/'+post.id" data-width="100%" data-numposts="5" data-order-by="reverse_time"></div>
					</div>
					<!-- End FB Comments -->
				</div>
				<!-- End Post -->
			</div>
		</div>
		<div v-else-if="isComplete && !post.id" class="container">
			<div class="row">
				<div class="col-md-12 col-xs-12" style="height: 400px; text-align: center;">
					<h2 style="margin-top: 100px;">This post is not exist or deleted</h2>
					<h5>Go back <a href="/">home</a></h5>
				</div>
			</div>
		</div>
		<!-- End Article ================================================== -->

		<div class="hideshare"></div>

		<!-- Begin Related ================================================== -->
		<div v-if="post.relevant && post.relevant.length > 0" class="graybg">
			<div class="container">
				<div class="row listrecent">

					<div v-for="pr in post.relevant" :key="pr.id" class="col-md-4">
						<div class="card">
							<a :href="'/post/'+linkPost(pr.title, pr.id)">
								<img class="img-fluid img-thumb" :src="pr.coverUrl" alt="">
							</a>
							<div class="card-body">
								<h2 class="card-title">
									<a :href="'/post/'+linkPost(pr.title, pr.id)">{{pr.title}}</a>
								</h2>
								<div class="wrapfooter">
									<span class="meta-footer-thumb">
										<a :href="'/author/'+pr.author.id">
											<img v-if="pr.author.avt" class="author-thumb" :src="pr.author.avt" alt="avatar">
											<svg v-else class="author-thumb" version="1.1" id="avatar" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 480 480" style="enable-background:new 0 0 480 480;" xml:space="preserve"><g><g>
												<path d="M240,0C107.664,0,0,107.664,0,240c0,57.96,20.656,111.184,54.992,152.704c0.088,0.12,0.096,0.272,0.192,0.384,c24.792,29.896,55.928,52.816,90.624,67.624c0.4,0.168,0.792,0.352,1.192,0.52c2.808,1.184,5.648,2.28,8.496,3.352
														c1.12,0.424,2.24,0.856,3.376,1.264c2.456,0.88,4.928,1.712,7.416,2.512c1.592,0.512,3.184,1.016,4.792,1.496,c2.2,0.656,4.408,1.288,6.632,1.888c1.952,0.528,3.92,1.016,5.888,1.488c1.992,0.48,3.992,0.96,6,1.384
														c2.24,0.48,4.504,0.904,6.776,1.32c1.824,0.336,3.64,0.688,5.48,0.984c2.52,0.408,5.056,0.728,7.6,1.056,c1.64,0.208,3.272,0.448,4.92,0.624c2.88,0.304,5.784,0.52,8.696,0.72c1.352,0.096,2.696,0.24,4.056,0.312
														c4.248,0.24,8.544,0.368,12.872,0.368s8.624-0.128,12.888-0.352c1.36-0.072,2.704-0.216,4.056-0.312,c2.912-0.208,5.816-0.416,8.696-0.72c1.648-0.176,3.28-0.416,4.92-0.624c2.544-0.328,5.08-0.648,7.6-1.056
														c1.832-0.296,3.656-0.648,5.48-0.984c2.264-0.416,4.528-0.84,6.776-1.32c2.008-0.432,4-0.904,6-1.384,c1.968-0.48,3.936-0.968,5.888-1.488c2.224-0.592,4.432-1.232,6.632-1.888c1.608-0.48,3.2-0.984,4.792-1.496
														c2.488-0.8,4.96-1.632,7.416-2.512c1.128-0.408,2.248-0.84,3.376-1.264c2.856-1.072,5.688-2.176,8.496-3.352,c0.4-0.168,0.792-0.352,1.192-0.52c34.688-14.808,65.832-37.728,90.624-67.624c0.096-0.112,0.104-0.272,0.192-0.384
														C459.344,351.184,480,297.96,480,240C480,107.664,372.336,0,240,0z M337.256,441.76c-0.12,0.056-0.232,0.12-0.352,0.176,c-2.856,1.376-5.76,2.672-8.688,3.936c-0.664,0.28-1.32,0.568-1.984,0.848c-2.56,1.072-5.152,2.088-7.76,3.064
														c-1.088,0.408-2.176,0.808-3.272,1.192c-2.312,0.824-4.632,1.616-6.976,2.368c-1.456,0.464-2.92,0.904-4.384,1.336,c-2.08,0.624-4.168,1.224-6.28,1.784c-1.776,0.472-3.568,0.904-5.36,1.328c-1.88,0.448-3.752,0.904-5.648,1.304
														c-2.072,0.44-4.16,0.816-6.24,1.192c-1.688,0.312-3.368,0.64-5.072,0.912c-2.344,0.368-4.712,0.664-7.072,0.96,c-1.496,0.192-2.984,0.416-4.496,0.576c-2.696,0.288-5.416,0.472-8.128,0.664c-1.208,0.08-2.408,0.216-3.632,0.28
														c-3.96,0.208-7.928,0.32-11.912,0.32s-7.952-0.112-11.904-0.32c-1.216-0.064-2.416-0.192-3.632-0.28,c-2.72-0.184-5.432-0.376-8.128-0.664c-1.512-0.16-3-0.384-4.496-0.576c-2.36-0.296-4.728-0.592-7.072-0.96
														c-1.704-0.272-3.384-0.6-5.072-0.912c-2.088-0.376-4.176-0.76-6.24-1.192c-1.896-0.4-3.776-0.856-5.648-1.304,c-1.792-0.432-3.584-0.856-5.36-1.328c-2.104-0.56-4.2-1.168-6.28-1.784c-1.464-0.432-2.928-0.872-4.384-1.336
														c-2.344-0.752-4.672-1.544-6.976-2.368c-1.096-0.392-2.184-0.792-3.272-1.192c-2.608-0.976-5.2-1.992-7.76-3.064,c-0.664-0.272-1.312-0.56-1.976-0.84c-2.928-1.256-5.832-2.56-8.696-3.936c-0.12-0.056-0.232-0.112-0.352-0.176
														c-27.912-13.504-52.568-32.672-72.576-55.952c15.464-56.944,59.24-102.848,115.56-121.112c1.112,0.68,2.272,1.288,3.416,1.928,c0.672,0.376,1.336,0.776,2.016,1.136c2.384,1.264,4.8,2.448,7.272,3.512c1.896,0.832,3.856,1.536,5.808,2.256
														c0.384,0.136,0.768,0.288,1.152,0.424c10.848,3.84,22.456,6.04,34.6,6.04s23.752-2.2,34.592-6.04,c0.384-0.136,0.768-0.288,1.152-0.424c1.952-0.72,3.912-1.424,5.808-2.256c2.472-1.064,4.888-2.248,7.272-3.512
														c0.68-0.368,1.344-0.76,2.016-1.136c1.144-0.64,2.312-1.248,3.432-1.936c56.32,18.272,100.088,64.176,115.56,121.112,C389.824,409.08,365.168,428.248,337.256,441.76z M152,176c0-48.52,39.48-88,88-88s88,39.48,88,88
														c0,30.864-16.008,58.024-40.128,73.736c-3.152,2.048-6.432,3.88-9.8,5.48c-0.4,0.192-0.792,0.392-1.192,0.576,c-23.168,10.536-50.592,10.536-73.76,0c-0.4-0.184-0.8-0.384-1.192-0.576c-3.376-1.6-6.648-3.432-9.8-5.48
														C168.008,234.024,152,206.864,152,176z M421.832,370.584c-18.136-53.552-59.512-96.832-112.376-117.392,C330.6,234.144,344,206.64,344,176c0-57.344-46.656-104-104-104s-104,46.656-104,104c0,30.64,13.4,58.144,34.552,77.192
														c-52.864,20.568-94.24,63.84-112.376,117.392C31.672,333.792,16,288.704,16,240C16,116.488,116.488,16,240,16s224,100.488,224,224,C464,288.704,448.328,333.792,421.832,370.584z"/></g></g>
											</svg>
										</a>
									</span>
									<span class="author-meta">
										<span class="post-name"><a :href="'/author/'+pr.author.id">{{pr.author.name}}</a></span><br/>
										<span class="post-date">{{pr.create}}</span> · <span class="post-read">{{pr.time}} min read</span>
									</span>
									<span class="post-read-more">
										<a :href="'/post/'+linkPost(pr.title, pr.id)" title="Read Story">Xem thêm</a>
									</span>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
		<!-- End Related Posts ================================================== -->
	</div>
</template>

<script>
import PostService from "../services/post.service";
import LinkMixin from "../mixins/link";

export default {
	name: 'Post',
	mixins: [LinkMixin],
	data(){
		return {
			post: {},
			social: {},
			likeLoading: false,
			bookmarkLoading: false,
			followLoading: false,
			isComplete: false,
			linkTW: ''
		};
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
	},
	created () {
		// fetch the data when the view is created and the data is already being observed
		// https://router.vuejs.org/guide/advanced/data-fetching.html
    this.fetchData()
	},
	mounted() {
		// FB API
		this.setupFB();
		// MathJax
		const mathjax = document.createElement("script");
		// mathjax.setAttribute("src","https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js");
		mathjax.setAttribute("src","https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-AMS_HTML");
		mathjax.async = true;
		mathjax.type = "text/javascript";
		document.head.appendChild(mathjax);
		// Prism
    const prism = document.createElement("script");
    prism.setAttribute("src","/static/assets/js/prism.js");
		prism.async = false;
		prism.type = "text/javascript";
		document.head.appendChild(prism);
	},
	watch: {
    // call again the method if the route changes
    '$route': 'routeChange'
  },
	methods: {
		routeChange() {
			console.log("In routeChange");
			this.prismHighlightAll();
			this.mathJaxTypeset();
			this.fbXFBMLParse();
		},
		prismHighlightAll() {
			setTimeout(() => {
				if (typeof window.Prism === 'undefined') {
					// Prism is undefined
					return;
				}
				if(window.Prism === null) {
					return;
				}
				// Rerun Prism syntax highlighting on the current page
				// console.log("Prism.highlightAll");
				window.Prism.highlightAll();
			}, 3000);
		},
		mathJaxTypeset() {
			setTimeout(() => {
				if (typeof window.MathJax === 'undefined') {
					// MathJax is undefined
					return;
				}
				if(window.MathJax === null) {
					return;
				}
				// Rerun MathJax syntax on the current page
				// console.log("MathJax.Typeset");
				window.MathJax.Hub.Queue(["Typeset",window.MathJax.Hub]);
			}, 3000);
		},
		fbXFBMLParse() {
			setTimeout(() => {
				if (typeof window.FB.XFBML === 'undefined') {
					// window.FB.XFBML is undefined
					return;
				}
				if(window.FB.XFBML === null) {
					return;
				}
				// Rerun FB.XFBML parse on the current page
				// console.log("FB.XFBML.parse");
				window.FB.XFBML.parse();
			}, 3000);
		},
		// linkPost(title, id) {
		// 	return title.toLowerCase().replace(/[:?&]/g,'-').split(' ').join('-') + "-" + id;
		// },
		// linkTag(name, id) {
		// 	return name.toLowerCase().replace(/[:?&]/g,'-').split(' ').join('-') + "-" + id;
		// },
		fetchData () {
			// if id exist them call method get post infomation.
			//console.log('this.$route.params.id: ' + this.$route.params.id);
			let pid = this.$route.params.id;
			if(pid && pid.length > 0){
				let id = pid.split('-').slice(-1)[0];
				this.getPost(id);
			} else {
				this.isComplete = true;
			}
		},
		getPost(id) {
			// console.log('In getPost: ' + id);
			PostService.getPost(id).then(data => {
				//console.log(data);
				if(data && data.err >= 0 && data.post && data.post.status === "Active") {
					// console.log('=============== Set Data Post View ===============');
					this.post = data.post;
					this.social = data.social;
					this.linkTW = this.shareTW();
					// this.renderMeta(data.post);
					let path = this.$route.path;
					let linkPost = '/post/' + this.linkPost(this.post.title, this.post.id);
					// console.log("path: " + path);
					// console.log("linkPost: " + linkPost);
					if (path !== linkPost) {
						// console.log("this.$router.replace linkPost: " + linkPost);
						// https://router.vuejs.org/guide/essentials/navigation.html#router-replace-location-oncomplete-onabort
						this.$router.replace(linkPost);
					} else {
						this.prismHighlightAll();
						this.mathJaxTypeset();
						this.fbXFBMLParse();
					}
				}
				this.isComplete = true;
			});
		},
		renderMeta(post) {
			// Render meta data.
			// <meta property="fb:app_id"        content="177811139482503" />
			// <meta property="og:url"           content="https://www.your-domain.com/your-page.html" />
			// <meta property="og:type"          content="article" />
			// <meta property="og:title"         content="Your Website Title" />
			// <meta property="og:description"   content="Your description" />
			// <meta property="og:image"         content="https://www.your-domain.com/path/image.jpg" />
			if(post.id) {
				console.log("Render meta data.");
				// og:image
				const image = document.createElement("meta");
				image.setAttribute("property","og:image");
				image.content = 'https://vallicon.com' + post.coverUrl;
				document.head.insertBefore(image, document.head.childNodes[0]);
				// og:description
				const description = document.createElement("meta");
				description.setAttribute("property","og:description");
				description.content = post.description;
				document.head.insertBefore(description, document.head.childNodes[0]);
				// og:title
				const title = document.createElement("meta");
				title.setAttribute("property","og:title");
				title.content = 'Vallicon | ' + post.title;
				document.head.insertBefore(title, document.head.childNodes[0]);
				// og:type
				const type = document.createElement("meta");
				type.setAttribute("property","og:type");
				type.content = 'article';
				document.head.insertBefore(type, document.head.childNodes[0]);
				// og:url
				const url = document.createElement("meta");
				url.setAttribute("property","og:url");
				url.content = 'https://vallicon.com/post/'+this.linkPost(post.title, post.id);
				document.head.insertBefore(url, document.head.childNodes[0]);
				// fb:app_id
				const app_id = document.createElement("meta");
				app_id.setAttribute("property","fb:app_id");
				app_id.content = '177811139482503';
				document.head.insertBefore(app_id, document.head.childNodes[0]);
			}
		},
		likePost() {
			// console.log('In likePost');
			let formData = {
				"pid": this.post.id
			};
			if(this.social.like) { // Unlike Post
				formData['action'] = 'unlikePost';
			} else { // Like Post
				formData['action'] = 'likePost';
			}
			if(!this.likeLoading) {
				this.likeLoading = true;
				PostService.likePost(formData).then(data => {
					if(data && data.err >= 0) {
						if(this.social.like) { // Unlike Post
							this.post.like--;
						} else { // Like Post
							this.post.like++;
						}
						this.social.like = !this.social.like;
					}
				});
				setTimeout(() => {
					this.likeLoading = false;
				}, 5000);
			}
		},
		bookmarkPost() {
			// console.log('In bookmarkPost');
			let formData = {
				"pid": this.post.id
			};
			if(this.social.bookmark) { // Unbookmark Post
				formData['action'] = 'unbookmarkPost';
			} else { // Bookmark Post
				formData['action'] = 'bookmarkPost';
			}
			if(!this.bookmarkLoading) {
				this.bookmarkLoading = true;
				PostService.bookmarkPost(formData).then(data => {
					if(data && data.err >= 0) {
						this.social.bookmark = !this.social.bookmark;
					}
				});
				setTimeout(() => {
					this.bookmarkLoading = false;
				}, 5000);
			}
		},
		followUser(aid) {
			// console.log("In followUser: " + aid);
			let formData = {
				"uid": aid
			};
			if(this.social.follow) { // Unfollow User
				formData['action'] = 'unfollowUser';
			} else { // Follow User
				formData['action'] = 'followUser';
			}
			if(!this.followLoading) {
				this.followLoading = true;
				PostService.followUser(formData).then(data => {
					if(data && data.err >= 0) {
						this.social.follow = !this.social.follow;
					}
				});
				setTimeout(() => {
					this.followLoading = false;
				}, 5000);
			}
		},
		async setupFB() {
      // console.log("In setupFB");
      await this.loadFacebookSDK(document, "script", "facebook-jssdk");
      await this.initFacebook();
    },
    async initFacebook() {
      window.fbAsyncInit = function() {
        window.FB.init({
          appId      : '177811139482503',
          cookie     : true,
          xfbml      : true,
          version    : 'v15.0'
        });
        window.FB.AppEvents.logPageView();
      };
    },
    async loadFacebookSDK(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {return;}
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
		},
		shareFB() {
			// https://stackoverflow.com/questions/23781698/fb-ui-share-set-the-title-message-and-image
			console.log("In shareFB");
			// window.FB.ui({
			// 	method: 'share_open_graph',
			// 	action_type: 'og.shares',
			// 	display: 'popup',
			// 	action_properties: JSON.stringify({
			// 		object: {
			// 			'og:url': 'https://vallicon.com/post/' + this.linkPost(this.post.title, this.post.id),
			// 			'og:title': 'Vallicon | ' + this.post.title,
			// 			'og:description': this.post.description,
			// 			'og:image': 'https://vallicon.com' + this.post.coverUrl
			// 		}
			// 	})
			// }, function(resp) {
			// 	// Action after response
			// 	console.log(resp);
			// });
			window.FB.ui({
				method: 'share',
				href: 'https://vallicon.com/post/' + this.linkPost(this.post.title, this.post.id),
			}, function(){});
		},
		shareTW() {
			var hrefTW = "https://twitter.com/intent/tweet?";
			let text = this.post.title;
			// text = text.length > 200 ? text.substr(0, 200) + "..." : text;
			text = encodeURI(text);
			hrefTW += "text=" + text;
			let url = "https://vallicon.com/post/" + this.linkPost(this.post.title, this.post.id);
			hrefTW += "&url=" + encodeURI(url);
			let hashtags = "Vallicon";
			hrefTW += "&hashtags=" + hashtags;
			let via = "vallicon";
			hrefTW += "&via=" + via;
			return hrefTW;
		}
	}
}
</script>

<style scoped>
	@import '../assets/css/prism.css';

pre {
	font-size: 75%;
}
pre code {
	font-size: 75%;
}
</style>