import axios from "axios";
import store from "../store";
import config from "../config/config.js";

const API_URL = config.API_URL;
// const API_URL = 'http://localhost:8080/api/v1';

class PostService {
  savePost(formData) {
    return axios.post(API_URL + "/edit", formData, {
      headers: {
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + (store.state.auth.user ? store.state.auth.user.token : '')
      }
    }).then(res => {
      return res.data;
    }).catch(e => {
      console.log(e);
    });
  }
  getPost(id) {
    return axios.get(API_URL + "/post?p="+id, {
      headers: {
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + (store.state.auth.user ? store.state.auth.user.token : '')
      }
    }).then(res => {
      return res.data;
    }).catch(e => {
      console.log(e);
    });
  }
  likePost(formData) {
    return axios.post(API_URL + "/social", formData, {
      headers: {
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + (store.state.auth.user ? store.state.auth.user.token : '')
      }
    }).then(res => {
      return res.data;
    }).catch(e => {
      console.log(e);
    });
  }
  bookmarkPost(formData) {
    return axios.post(API_URL + "/social", formData, {
      headers: {
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + (store.state.auth.user ? store.state.auth.user.token : '')
      }
    }).then(res => {
      return res.data;
    }).catch(e => {
      console.log(e);
    });
  }
  followUser(formData) {
    return axios.post(API_URL + "/social", formData, {
      headers: {
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + (store.state.auth.user ? store.state.auth.user.token : '')
      }
    }).then(res => {
      return res.data;
    }).catch(e => {
      console.log(e);
    });
  }
  getListPost(page) {
    return axios.get(API_URL + "/posts?page="+page, {
      headers: {
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + (store.state.auth.user ? store.state.auth.user.token : '')
      }
    }).then(res => {
      return res.data;
    }).catch(e => {
      console.log(e);
    });
  }
  deletePost(formData) {
    return axios.post(API_URL + "/edit", formData, {
      headers: {
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + (store.state.auth.user ? store.state.auth.user.token : '')
      }
    }).then(res => {
      return res.data;
    }).catch(e => {
      console.log(e);
    });
  }
  statusPost(formData) {
    return axios.post(API_URL + "/edit", formData, {
      headers: {
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + (store.state.auth.user ? store.state.auth.user.token : '')
      }
    }).then(res => {
      return res.data;
    }).catch(e => {
      console.log(e);
    });
  }
  getAllPosts() {
    return axios.get(API_URL + "/admin/allposts", {
      headers: {
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + (store.state.auth.user ? store.state.auth.user.token : '')
      }
    }).then(res => {
      return res.data;
    }).catch(e => {
      console.log(e);
    });
  }
  getSearch() {
    return axios.get(API_URL + "/search", {
      headers: {
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + (store.state.auth.user ? store.state.auth.user.token : '')
      }
    }).then(res => {
      return res.data;
    }).catch(e => {
      console.log(e);
    });
  }
}

export default new PostService();