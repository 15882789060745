import Vue from 'vue';
import App from './App.vue';
import { router } from "./router";
import store from "./store";
import Vuex from "vuex";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
// import '@/assets/css/prism.css';
import '@/assets/css/vallicon.css';
import '@/assets/css/login.css';
import { BootstrapVue, IconsPlugin, TooltipPlugin } from 'bootstrap-vue';
import VeeValidate from "vee-validate";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt, faLock, faChartLine, faFileWord, faTimes, 
  faArrowDown, faChevronDown, faBookmark, faCaretRight, faTrash, faEdit,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF, faGooglePlusG, faTwitter,
} from '@fortawesome/free-brands-svg-icons';
library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt, faLock, faChartLine, faFacebookF, 
  faGooglePlusG, faTwitter, faFileWord, faTimes, faArrowDown, faChevronDown, faBookmark, faCaretRight, 
  faTrash, faEdit);
import Treeselect from '@riophae/vue-treeselect';


Vue.config.productionTip = false;

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(TooltipPlugin);
Vue.use(VeeValidate);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('treeselect', Treeselect);

Vue.use(Vuex);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
