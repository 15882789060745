export default {
  methods: {
    linkPost(title, id) {
			return title.toLowerCase().replace(/[:?&]/g,'-').split(' ').join('-') + "-" + id;
		},
		linkTag(name, id) {
			return name.toLowerCase().replace(/[:?&]/g,'-').split(' ').join('-') + "-" + id;
		},
  }
}
