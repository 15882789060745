
<template>
	<div class="container">
		<div class="row">
			<div class="col-md-2 col-xs-12">&nbsp;</div>
			<div class="col-md-8 col-md-offset-2 col-xs-12">
				<div class="omb_login">
					<h3 class="omb_authTitle">Sign up or <a href="/login">Login</a></h3>
					<div class="row omb_row-sm-offset-3">
						<div class="col-xs-12 col-sm-9">
							<form name="form" @submit.prevent="handleRegister" class="omb_loginForm">
								<div 
									v-if="submitted && message" 
									class="alert" 
									:class="successful ? 'alert-success' : 'alert-danger'" 
									role="alert">{{message}}</div>
								<div class="row-item">
									<div class="input-group">
										<div class="input-group-prepend">
											<div class="input-group-text"><font-awesome-icon icon="user" /></div>
										</div>
										<input 
											ref="first"
											v-model="user.username"
											v-validate="'required|min:3|max:50'"
											type="text"
											class="form-control"
											:class="errors.has('username') ? 'is-invalid' : (submitted ? 'is-valid' : '')"
											placeholder="Your name" 
											name="username"
										/>
									</div>
									<span v-if="submitted && errors.has('username')" class="help-block">{{errors.first('username')}}</span>
								</div>
								<div class="row-item">
									<div class="input-group">
										<div class="input-group-prepend">
											<div class="input-group-text"><font-awesome-icon icon="user" /></div>
										</div>
										<input 
											v-model="user.email"
											v-validate="'required|email|max:100'"
											type="email"
											class="form-control"
											:class="errors.has('email') ? 'is-invalid' : (submitted ? 'is-valid' : '')"
											placeholder="Email address" 
											name="email"
										/>
									</div>
									<span v-if="submitted && errors.has('email')" class="help-block">{{errors.first('email')}}</span>
								</div>
								<div class="row-item">
									<div class="input-group">
										<div class="input-group-prepend">
											<div class="input-group-text"><font-awesome-icon icon="lock" /></div>
										</div>
										<input 
											v-model="user.password"
											v-validate="'required|min:6|max:100'"
											type="password"
											class="form-control"
											:class="errors.has('password') ? 'is-invalid' : (submitted ? 'is-valid' : '')"
											placeholder="Password" 
											name="password"
											ref="password"
										/>
									</div>
									<span v-if="submitted && errors.has('password')" class="help-block">{{errors.first('password')}}</span>
								</div>
								<div class="row-item">
									<div class="input-group">
										<div class="input-group-prepend">
											<div class="input-group-text"><font-awesome-icon icon="lock" /></div>
										</div>
										<input 
											v-model="user.repassword"
											v-validate="'required|confirmed:password'" 
											name="repassword" 
											type="password" 
											class="form-control" 
											:class="errors.has('repassword') ? 'is-invalid' : (submitted ? 'is-valid' : '')"
											placeholder="Confirm Password" 
											data-vv-as="password"
										/>
									</div>
									<span v-if="submitted && errors.has('repassword')" class="help-block">{{errors.first('repassword')}}</span>
								</div>
								<button class="btn btn-primary btn-block" type="submit" :disabled="loading">
									<span v-show="loading" class="spinner-border spinner-border-sm"></span> <span>Sign up</span>
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import User from "../models/user";

export default {
	name: 'Register',
	data() {
		return {
			user: new User('', '', '', ''),
			submitted: false,
			successful: false,
			loading: false,
			message: ''
		};
	},
	computed: {
		loggedIn() {
			return this.$store.state.auth.status.loggedIn;
		}
	},
	mounted() {
		if(this.loggedIn) {
			this.$router.push('/');
		}
		this.$refs.first.focus();
	},
	methods: {
		handleRegister() {
			this.message = '';
			this.submitted = true;
			this.loading = true;
			this.$validator.validateAll().then(isValid => {
				//console.log(this.user);
				if(isValid) {
					this.$store.dispatch('auth/register', this.user).then(data => {
						this.message = data.msg;
						if(data.err >= 0) {
							this.successful = true;
							setTimeout(() => {
								this.submitted = false;
								this.$router.push('/');
							}, 3000);
						} else {
							this.successful = false;
							//this.loading = false
							setTimeout(() => {this.loading = false}, 2000);
						}
					}, error => {
						this.message = (error.response && error.response.data) ||
								error.message ||
								error.toString();
						this.successful = false;
						//this.loading = false
						setTimeout(() => {this.loading = false}, 2000);
					});
				} else {
					this.loading = false;
				}
			});
		}
	}
};
</script>
