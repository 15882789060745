<template>
  <div class="container" style="min-height: 400px;">
    <div class="section-title">
      <h2><span>Your stories</span> <a href="/edit" class="btn btn-sm btn-outline-success float-right">Add new post</a></h2>
    </div>
    <div class="card">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li class="nav-item">
            <a class="nav-link" :class="isDraft ? 'active' : ''" href @click.prevent="storyDraft">
              Drafts <span v-if="isDraft" class="badge badge-primary badge-pill">{{total}}</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" :class="isDraft ? '' : 'active'" href @click.prevent="storyPublish">
              Published <span v-if="!isDraft" class="badge badge-primary badge-pill">{{total}}</span>
            </a>
          </li>
        </ul>
      </div>
      <div v-if="posts.listPost && posts.listPost.length > 0" id="listPost" class="card-body">
        <div v-for="(p, i) in posts.listPost" :key="p.id">
          <div v-if="i > 0" class="section-title"><h2>&nbsp;</h2></div>
          <h5 class="card-title"><a :href="'/post/'+linkPost(p.title, p.id)">{{p.title}}</a></h5>
          <div class="card-text">
            <small class="text-muted">Last edited {{p.updateTime}} ·<!--span class="dot"></span--> {{p.time}} min read ({{p.word}} words) </small>
            <a data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"><font-awesome-icon icon="chevron-down"/></a>
            <div class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item" :href="'/edit/'+p.id"><font-awesome-icon icon="edit" /><span class="submenu">Edit</span></a>
              <a class="dropdown-item" href @click.prevent="deletePost(p.id)"><font-awesome-icon icon="times" /><span class="submenu">Delete</span></a>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="card-body">
        <p class="card-text">You have no posts yet.</p>
        <!-- <p class="card-text">Bạn chưa có bài viết nào.</p> -->
      </div>
      
      <a v-if="isMore" href @click.prevent="loadMore">
        <div class="card-footer text-center"><font-awesome-icon icon="arrow-down" /> Load more</div>
      </a>
    </div>
  </div>
</template>

<script>
import StoriesService from "../services/stories.service";
import PostService from "../services/post.service";
import UserService from "../services/user.service";
import LinkMixin from "../mixins/link";

export default {
  name: "Stories",
  mixins: [LinkMixin],
  data() {
    return {
      isMore: false,
      page: 1,
      status: "Draft",
      total: 0,
      posts: {}
    };
  },
  computed: {
		currentUser() {
			return this.$store.state.auth.user;
    },
    isDraft() {
			return this.status.toUpperCase() === 'DRAFT';
    },
    isAdmin() {
			if(this.currentUser && this.currentUser.role) {
				return this.currentUser.role == 'ADqrumXT2ga' || this.currentUser.role == 'RKxqtbyt58d';
			}
			return false;
		},
	},
	created () {
    if(!this.isAdmin) {
      this.$router.push('/');
      return;
    }
    // checkToken
		this.checkToken();
		// fetch the data when the view is created and the data is already being observed
		// https://router.vuejs.org/guide/advanced/data-fetching.html
    this.fetchData();
  },
  methods: {
    checkToken() {
			// console.log("In checkToken Stories");
			if(this.currentUser) {
				UserService.checkToken().then(data => {
					// console.log(data);
					if(data && data.err < 0) {
						this.$store.dispatch('auth/logout');
						window.location.reload();
					}
				});
			}
    },
    // linkPost(title, id) {
		// 	return title.toLowerCase().replace(/[:?&]/g,'-').split(' ').join('-') + "-" + id;
		// },
    fetchData () {
			this.getStories();
		},
		getStories() {
      // console.log('In getStories: ' + this.page);
			StoriesService.getStories(this.status, this.page).then(data => {
				// console.log(data);
				if(data && data.err >= 0) {
					// console.log('=============== Set List Data Posts Stories ===============');
          this.posts = data.posts;
          this.isMore = data.posts.isMore;
          this.total = data.posts.total;
				}
			});
    },
    reset() {
      this.isMore = false;
      this.page = 1;
      this.status = "Draft";
      this.total = 0;
      this.posts = {};
    },
    loadMore() {
      this.page++;
      // console.log('In loadMore Stories: ' + this.page);
			StoriesService.getStories(this.status, this.page).then(data => {
				// console.log(data);
				if(data && data.err >= 0) {
					// console.log('=============== Set List Data loadMore Stories ===============');
          this.isMore = data.posts.isMore;
          this.posts.isMore = data.posts.isMore;
          this.posts.page = data.posts.page;
          // append array listpost.
          this.posts.listPost.push(...data.posts.listPost);
				}
			});
    },
    storyDraft() {
      // console.log('In storyDraft: ' + this.page);
      this.reset();
      this.status = 'Draft';
      this.getStories();
    },
    storyPublish() {
      // console.log('In storyPublish: ' + this.page);
      this.reset();
      this.status = 'Active';
      this.getStories();
    },
    deletePost(id) {
      // console.log('In deletePost: ' + id);
      this.confirmDeletePost(id);
    },
    confirmDeletePost(id) {
      // console.log('In confirmDeletePost: ' + id);
      this.$bvModal.msgBoxConfirm('Are you want to delete this post?', {
        title: 'Delete Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then(value => {
        // console.log('confirmDeletePost: ' + value);
        if(value) { // Confirm OK
          let formData = {
            "id": id,
            "action": "deletePost"
          };
          PostService.deletePost(formData).then(data => {
            // console.log(data);
            if(data && data.err >= 0) {
              // delete data in vue: this.posts.listPost
              this.posts.listPost = this.posts.listPost.filter(pt => pt.id !== id);
              this.total--;
            }
          });
        }
      }).catch(err => {
        // An error occurred
        console.log(err);
      })
    }
  }
}
</script>
