import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import About from "./views/About.vue";
import Terms from "./views/Terms.vue";
import Privacy from "./views/Privacy.vue";
import Author from "./views/Author.vue";
import Edit from "./views/Edit.vue";
import Post from "./views/Post.vue";
import ListPost from "./views/ListPost.vue";
import Tag from "./views/Tag.vue";
import Topic from "./views/Topic.vue";
import Login from "./views/Login.vue";
import Register from "./views/Register.vue";
import Stories from "./views/Stories.vue";
import Bookmark from "./views/Bookmark.vue";

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'root',
      component: Home
    },
    {
      path: '/home',
      name: 'home',
      component: Home
    },
    {
      path: '/about',
      name: 'about',
      component: About
    },
    {
      path: '/terms',
      name: 'terms',
      component: Terms
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: Privacy
    },
    {
      path: '/author/:id',
      name: 'author',
      component: Author
    },
    {
      path: '/edit',
      name: 'edit',
      component: Edit
    },
    {
      path: '/edit/:id',
      name: 'update',
      component: Edit
    },
    {
      path: '/post/:id',
      name: 'post',
      component: Post
    },
    {
      path: '/posts',
      name: 'posts',
      component: ListPost
    },
    {
      path: '/tag/:id',
      name: 'tag',
      component: Tag
    },
    {
      path: '/topic/:id',
      name: 'topic',
      component: Topic
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/register',
      name: 'register',
      component: Register
    },
    {
      path: '/profile',
      name: 'profile',
      // lazy-loaded
      component: () => import('./views/Profile.vue')
    },
    {
      path: '/stories',
      name: 'stories',
      component: Stories
    },
    {
      path: '/bookmark',
      name: 'bookmark',
      component: Bookmark
    },
    {
      path: '/admin',
      name: 'admin',
      // lazy-loaded
      component: () => import('./views/Admin.vue')
    },
    // nếu không sẽ chuyển đến trang home
    { path: '*', redirect: '/' }
  ]
});

const publicPages = ['/', '/login', '/register', '/home', '/about', '/posts', '/terms', '/privacy'];

router.beforeEach((to, from, next) => {
  // console.log('to.path: ' + to.path);
  // console.log('from.path: ' + from.path);
  const authRequired = !publicPages.includes(to.path) && !to.path.includes('/post/') && !to.path.includes('/tag/') && !to.path.includes('/author/') && !to.path.includes('/topic/');
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in redirect to login page.
  if(authRequired && !loggedIn) {
    next({name: 'login', query: { redirect: to.path }});
  } else {
    next();
  }
});
