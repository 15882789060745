import axios from 'axios';
import store from "../store";
import config from "../config/config.js";

const API_URL = config.API_URL;
// const API_URL = 'http://localhost:8080/api/v1';

class UploadService {
  uploadCover(formData, onUploadProgress) {
    return axios.post(API_URL + "/upimgpost", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": 'Bearer ' + (store.state.auth.user ? store.state.auth.user.token : '')
      },
      onUploadProgress
    }).then(res => {
      return res.data;
    }).catch(e => {
      console.log(e);
    });
  }
  uploadPhotoContent(formData, onUploadProgress) {
    return axios.post(API_URL + "/upimgpost", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": 'Bearer ' + (store.state.auth.user ? store.state.auth.user.token : '')
      },
      onUploadProgress
    }).then(res => {
      return res.data;
    }).catch(e => {
      console.log(e);
    });
  }
  uploadAvatar(formData, onUploadProgress) {
    return axios.post(API_URL + "/avatar", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": 'Bearer ' + (store.state.auth.user ? store.state.auth.user.token : '')
      },
      onUploadProgress
    }).then(res => {
      return res.data;
    }).catch(e => {
      console.log(e);
    });
  }
}

export default new UploadService();