<template>
	<div class="container">
		<div class="row">
			<div class="col-md-2 col-xs-12">&nbsp;</div>
			<div class="col-md-8 col-md-offset-2 col-xs-12">
				<div class="mainheading">
					<div class="row post-top-meta">
						<div class="col-md-2" style="text-align: center;">
							<a :href="'/author/'+currentUser.id">
								<img v-if="currentUser.avt" :src="currentUser.avt" class="author-thumb" style="margin: 0px; float: none;" alt="avatar">
								<svg v-else class="author-thumb" version="1.1" id="avatar" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 480 480" style="enable-background:new 0 0 480 480; margin: 0px; float: none;" xml:space="preserve"><g><g>
									<path d="M240,0C107.664,0,0,107.664,0,240c0,57.96,20.656,111.184,54.992,152.704c0.088,0.12,0.096,0.272,0.192,0.384,c24.792,29.896,55.928,52.816,90.624,67.624c0.4,0.168,0.792,0.352,1.192,0.52c2.808,1.184,5.648,2.28,8.496,3.352
											c1.12,0.424,2.24,0.856,3.376,1.264c2.456,0.88,4.928,1.712,7.416,2.512c1.592,0.512,3.184,1.016,4.792,1.496,c2.2,0.656,4.408,1.288,6.632,1.888c1.952,0.528,3.92,1.016,5.888,1.488c1.992,0.48,3.992,0.96,6,1.384
											c2.24,0.48,4.504,0.904,6.776,1.32c1.824,0.336,3.64,0.688,5.48,0.984c2.52,0.408,5.056,0.728,7.6,1.056,c1.64,0.208,3.272,0.448,4.92,0.624c2.88,0.304,5.784,0.52,8.696,0.72c1.352,0.096,2.696,0.24,4.056,0.312
											c4.248,0.24,8.544,0.368,12.872,0.368s8.624-0.128,12.888-0.352c1.36-0.072,2.704-0.216,4.056-0.312,c2.912-0.208,5.816-0.416,8.696-0.72c1.648-0.176,3.28-0.416,4.92-0.624c2.544-0.328,5.08-0.648,7.6-1.056
											c1.832-0.296,3.656-0.648,5.48-0.984c2.264-0.416,4.528-0.84,6.776-1.32c2.008-0.432,4-0.904,6-1.384,c1.968-0.48,3.936-0.968,5.888-1.488c2.224-0.592,4.432-1.232,6.632-1.888c1.608-0.48,3.2-0.984,4.792-1.496
											c2.488-0.8,4.96-1.632,7.416-2.512c1.128-0.408,2.248-0.84,3.376-1.264c2.856-1.072,5.688-2.176,8.496-3.352,c0.4-0.168,0.792-0.352,1.192-0.52c34.688-14.808,65.832-37.728,90.624-67.624c0.096-0.112,0.104-0.272,0.192-0.384
											C459.344,351.184,480,297.96,480,240C480,107.664,372.336,0,240,0z M337.256,441.76c-0.12,0.056-0.232,0.12-0.352,0.176,c-2.856,1.376-5.76,2.672-8.688,3.936c-0.664,0.28-1.32,0.568-1.984,0.848c-2.56,1.072-5.152,2.088-7.76,3.064
											c-1.088,0.408-2.176,0.808-3.272,1.192c-2.312,0.824-4.632,1.616-6.976,2.368c-1.456,0.464-2.92,0.904-4.384,1.336,c-2.08,0.624-4.168,1.224-6.28,1.784c-1.776,0.472-3.568,0.904-5.36,1.328c-1.88,0.448-3.752,0.904-5.648,1.304
											c-2.072,0.44-4.16,0.816-6.24,1.192c-1.688,0.312-3.368,0.64-5.072,0.912c-2.344,0.368-4.712,0.664-7.072,0.96,c-1.496,0.192-2.984,0.416-4.496,0.576c-2.696,0.288-5.416,0.472-8.128,0.664c-1.208,0.08-2.408,0.216-3.632,0.28
											c-3.96,0.208-7.928,0.32-11.912,0.32s-7.952-0.112-11.904-0.32c-1.216-0.064-2.416-0.192-3.632-0.28,c-2.72-0.184-5.432-0.376-8.128-0.664c-1.512-0.16-3-0.384-4.496-0.576c-2.36-0.296-4.728-0.592-7.072-0.96
											c-1.704-0.272-3.384-0.6-5.072-0.912c-2.088-0.376-4.176-0.76-6.24-1.192c-1.896-0.4-3.776-0.856-5.648-1.304,c-1.792-0.432-3.584-0.856-5.36-1.328c-2.104-0.56-4.2-1.168-6.28-1.784c-1.464-0.432-2.928-0.872-4.384-1.336
											c-2.344-0.752-4.672-1.544-6.976-2.368c-1.096-0.392-2.184-0.792-3.272-1.192c-2.608-0.976-5.2-1.992-7.76-3.064,c-0.664-0.272-1.312-0.56-1.976-0.84c-2.928-1.256-5.832-2.56-8.696-3.936c-0.12-0.056-0.232-0.112-0.352-0.176
											c-27.912-13.504-52.568-32.672-72.576-55.952c15.464-56.944,59.24-102.848,115.56-121.112c1.112,0.68,2.272,1.288,3.416,1.928,c0.672,0.376,1.336,0.776,2.016,1.136c2.384,1.264,4.8,2.448,7.272,3.512c1.896,0.832,3.856,1.536,5.808,2.256
											c0.384,0.136,0.768,0.288,1.152,0.424c10.848,3.84,22.456,6.04,34.6,6.04s23.752-2.2,34.592-6.04,c0.384-0.136,0.768-0.288,1.152-0.424c1.952-0.72,3.912-1.424,5.808-2.256c2.472-1.064,4.888-2.248,7.272-3.512
											c0.68-0.368,1.344-0.76,2.016-1.136c1.144-0.64,2.312-1.248,3.432-1.936c56.32,18.272,100.088,64.176,115.56,121.112,C389.824,409.08,365.168,428.248,337.256,441.76z M152,176c0-48.52,39.48-88,88-88s88,39.48,88,88
											c0,30.864-16.008,58.024-40.128,73.736c-3.152,2.048-6.432,3.88-9.8,5.48c-0.4,0.192-0.792,0.392-1.192,0.576,c-23.168,10.536-50.592,10.536-73.76,0c-0.4-0.184-0.8-0.384-1.192-0.576c-3.376-1.6-6.648-3.432-9.8-5.48
											C168.008,234.024,152,206.864,152,176z M421.832,370.584c-18.136-53.552-59.512-96.832-112.376-117.392,C330.6,234.144,344,206.64,344,176c0-57.344-46.656-104-104-104s-104,46.656-104,104c0,30.64,13.4,58.144,34.552,77.192
											c-52.864,20.568-94.24,63.84-112.376,117.392C31.672,333.792,16,288.704,16,240C16,116.488,116.488,16,240,16s224,100.488,224,224,C464,288.704,448.328,333.792,421.832,370.584z"/></g></g>
								</svg>
							</a>
						</div>
						<div class="col-md-10">
							<a class="link-dark" :href="'/author/'+currentUser.id">{{currentUser.name}}</a>
							<p class="author-description">{{currentUser.bio}}</p>
							<span class="post-read">{{status}}</span>
						</div>
					</div>
				</div>
				<form id="frmPost" name="frmPost" class="form-horizontal">
					<div class="label-post">Title (<span class="text-danger">*</span>):</div>
					<div class="edit-post">
						<input ref="first" type="text" placeholder="Title post" required class="form-control col-xs-12 col-sm-12" 
							:class="(submitted && !title && title.length<100) ? 'is-invalid' : (submitted ? 'is-valid' : '')"
							v-model="title"
							/>
						<span v-if="submitted && !title && title.length<100" class="help-block">Title is invalid (in range (1,100) words)</span>
					</div>
					
					<div class="label-post">Cover (<span class="text-danger">*</span>): <button v-b-modal.coverModal type="button" class="btn btn-sm btn-outline-success">Upload Cover</button></div>
					<div class="edit-post" style="margin-top: 0.5rem;">
						<img v-if="coverUrl" :src="coverUrl" class="featured-image img-fluid" alt="cover" style="margin-bottom: 0;">
						<img v-else class="featured-image img-fluid" src="../assets/img/1570x1047.png" alt="cover" style="margin-bottom: 0;">
						<span v-if="submitted && !cover" class="help-block">Cover is require</span>
					</div>

					<div class="label-post">Content (<span class="text-danger">*</span>):</div>
					<div class="edit-post">
						<editor
							v-model="content"
							@onInit="handleInitEditor"
							@onSetContent="handleSetContentEditor"
							:init="editorConfig"
						/>
						<textarea v-model="content" class="form-control col-xs-12 col-sm-12" rows="10" placeholder="Content html post"></textarea>
						<span v-if="submitted && !content" class="help-block">Content is require</span>
					</div>
					
					<div class="label-post">Description (<span class="text-danger">*</span>):</div>
					<div class="edit-post">
						<textarea rows="3" class="form-control col-xs-12 col-sm-12" placeholder="Description post (summary of the post)"
							:class="(submitted && !description && description.length<1000) ? 'is-invalid' : (submitted ? 'is-valid' : '')"
							v-model="description"
							></textarea>
						<span v-if="submitted && !description && description.length<1000" class="help-block">Description is invalid (in range (1,1000) words)</span>
					</div>

					<div class="label-post" style="margin-top: 1rem;">Add tags:</div>
					<div class="edit-post" id="divtag">
						<input type="text" placeholder="Add a tag (up to 5 tags, typing and press ENTER to add)..." class="form-control col-xs-12 col-sm-12"
							v-model="tag"
							v-on:keyup="addTag($event)"
							:disabled="numTag>=5"
							/>
					</div>
					<div v-if="tagAlert" class="alert alert-info" role="alert">{{tagAlert}}</div>
					<div class="after-post-tags" style="margin-top: 1rem;">
						<ul class="tags" id="tags">
							<li v-for="(vtag, ktag) in mapTag" :key="ktag"><span>{{vtag}} <font-awesome-icon icon="times" class="close-tag" v-on:click="deleteTag(ktag)"/></span></li>
						</ul>
					</div>

					<div v-if="submitted && message" class="alert" :class="successful ? 'alert-success' : 'alert-danger'" role="alert">{{message}}</div>
					<div class="edit-post">
						<a href="/edit" class="btn btn-outline-success hidden-sm-down float-left">New post</a>
						<span class="float-right" style="text-align: right">
							<a v-if="id" :href="'/post/'+linkPost(title, id)" target="_blank" class="btn btn-outline-info" :class="loading ? 'disabled' : ''">Preview</a>
							<button :disabled="loading" type="button" class="btn btn-outline-primary" @click="savePost" style="margin-left: 5px;">Save</button>
							<button v-if="isDraft" :disabled="loading" type="button" class="btn btn-outline-success" @click="publishPost" style="margin-left: 5px;">Publish</button>
						</span>
					</div>
				</form>
			</div>
		</div>

		<b-modal id="coverModal" ref="coverModal" title="Upload Photo"
			scrollable centered button-size="sm"
			:ok-disabled="loading"
			:cancel-disabled="loading"
      @show="resetModalCover"
			@hidden="resetModalCover"
      @ok="handleOkCover" >
			<form name="frmCover" ref="frmCover" class="form-horizontal" @submit.stop.prevent="handleSubmitCover">
				<div v-if="message" role="alert" class="alert" :class="successful ? 'alert-success' : 'alert-danger'">{{message}}</div>
				<div class="form-group">
					<div class="col-xs-12" style="margin-bottom: 5px;">
						<div v-if="coverImg" class="progress progress-sm">
							<div class="progress-bar bg-success" role="progressbar" aria-valuemin="0" aria-valuemax="100"
								:aria-valuenow="coverProgress"
								:style="{ width: coverProgress + '%' }"
							>{{ coverProgress }}%</div>
						</div>
					</div>
					<div class="col-xs-12">
						<b-form-file v-model="coverImg" accept="image/jpeg, image/png" drop-placeholder="Drop photo here..."></b-form-file>
					</div>
				</div>
			</form>
		</b-modal>
	</div>
</template>

<script>
import { BModal, VBModal } from 'bootstrap-vue';
import Editor from '@tinymce/tinymce-vue';
import UploadService from "../services/upload.service";
import PostService from "../services/post.service";
import UserService from "../services/user.service";
// import config from "../config/config.js";
import LinkMixin from "../mixins/link";

export default {
	name: "Edit",
	mixins: [LinkMixin],
	components: {
		'b-modal': BModal,
		'editor': Editor
	},
	directives: {
    // Note that Vue automatically prefixes directive names with `v-`
    'b-modal': VBModal
  },
	data() {
		return {
			submitted: false,
			loading: false,
			successful: false,
			message: '',
			id: '',
			cover: '',
			coverUrl: '',
			coverImg: null,
			coverProgress: 0,
			title: '',
			content: '',
			editorConfig: {
				min_height: 100,
				height: 500,
				min_width: 400,
				menubar: true,
				plugins: [
					'advlist autolink lists link image charmap print preview anchor',
					'searchreplace visualblocks code codesample fullscreen',
					'insertdatetime media table paste code help wordcount emoticons'
				],
				toolbar:
					'undo redo | formatselect | bold italic underline | code codesample | \
					link image media | bullist numlist | forecolor backcolor | \
					alignleft aligncenter alignright alignjustify | outdent indent | \
					fontsizeselect | quote removeformat emoticons help',
				plugin_preview_width: 900,
				plugin_preview_height: 400,
				fontsize_formats: '8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 18pt 20pt 22pt 24pt 28pt 30pt 32pt 34pt 36pt 40pt 44pt 48pt 52pt 60pt 72pt 96pt',
				convert_urls: false,
				image_class_list: [
						{title: 'Responsive image', value: 'featured-image img-fluid'}
				],
				images_upload_handler: (blobInfo, success, failure, progress) => {
					this.uploadPhotoContent(blobInfo, success, failure, progress);
				},
				codesample_dialog_height: 400,
        codesample_languages: [
					{text: 'Java', value: 'java'},
					{text: 'C', value: 'c'},
					{text: 'C#', value: 'csharp'},
					{text: 'C++', value: 'cpp'},
					{text: 'Python', value: 'python'},
					{text: 'HTML/XML', value: 'markup'},
					{text: 'JavaScript', value: 'javascript'},
					{text: 'CSS', value: 'css'},
					{text: 'PHP', value: 'php'},
					{text: 'Ruby', value: 'ruby'},
					{text: 'Bash', value: 'bash'},
					{text: 'JSON', value: 'json'},
					{text: 'Go', value: 'go'},
					{text: 'Lua', value: 'lua'},
					{text: 'Erlang', value: 'erlang'},
					{text: 'Haskell', value: 'haskell'},
					{text: 'MATLAB', value: 'matlab'},
					{text: 'LaTeX', value: 'latex'},
					{text: 'Perl', value: 'perl'},
					{text: 'R', value: 'r'},
					{text: 'Rust', value: 'rust'},
					{text: 'SQL', value: 'sql'},
					{text: 'Swift', value: 'swift'},
					{text: 'Docker', value: 'docker'},
					{text: 'Properties', value: 'properties'}
        ],
				setup: function (editor) {
					editor.ui.registry.addButton('quote', {
						text: 'Quote',
						onAction: () => {editor.insertContent('<blockquote>Quote content</blockquote>');}
					});
        }
			},
			editorInstance: null,
			description: '',
			tag: '',
			listtag: '',
			numTag: 0,
			mapTag: {},
			tagAlert: '',
			status: 'Draft',
			word: 0
		}
	},
	computed: {
		currentUser() {
			return this.$store.state.auth.user;
		},
		isDraft() {
			return this.status.toUpperCase() === 'DRAFT';
		},
		isAdmin() {
			if(this.currentUser && this.currentUser.role) {
				return this.currentUser.role == 'ADqrumXT2ga' || this.currentUser.role == 'RKxqtbyt58d';
			}
			return false;
		},
	},
	mounted() {
		this.$refs.first.focus();
	},
	created () {
		if(!this.isAdmin) {
      this.$router.push('/');
      return;
    }
    // checkToken
		this.checkToken();
		// fetch the data when the view is created and the data is already being observed
		// https://router.vuejs.org/guide/advanced/data-fetching.html
    this.fetchData();
	},
	watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
	methods: {
		checkToken() {
			// console.log("In checkToken Edit");
			if(this.currentUser) {
				UserService.checkToken().then(data => {
					// console.log(data);
					if(data && data.err < 0) {
						this.$store.dispatch('auth/logout');
						window.location.reload();
					}
				});
			}
		},
		// linkPost(title, id) {
		// 	return title.toLowerCase().replace(/[:?&]/g,'-').split(' ').join('-') + "-" + id;
		// },
		resetAll() {
			this.submitted = false;
			this.loading = false;
			this.successful = false;
			this.message = '';
			this.id = '';
			this.cover = '';
			this.coverUrl = '';
			this.coverImg = null;
			this.coverProgress = 0;
			this.title = '';
			this.content = '';
			this.editorInstance = null;
			this.description = '';
			this.tag = '';
			this.listtag = '';
			this.numTag = 0;
			this.mapTag = {};
			this.tagAlert = '';
			this.status = 'Draft';
			this.word = 0;
		},
		checkFormCover() {
			return this.coverImg != null;
		},
		resetModalCover() {
			this.submitted = false;
			this.loading = false,
			this.successful = false;
			this.message = '';
			this.coverImg = null;
			this.coverProgress = 0;
		},
		handleOkCover(bvModalEvt) {
			this.submitted = true;
			this.loading = true;
			// Prevent modal from closing
			bvModalEvt.preventDefault();
			// Exit when the form isn't valid
			// console.log('this.checkFormCover():' + this.checkFormCover());
			if (!this.checkFormCover()) {
				this.submitted = false;
				this.loading = false;
				this.message = 'Photo is require';
				this.successful = false;
				setTimeout(() => {
					this.message = '';
				}, 3000);
				return;
			}
			// Trigger submit handler
			this.handleSubmitCover();
			this.submitted = false;
		},
		handleSubmitCover() {
			// Upload Photo...
			this.coverProgress = 0;
			let formData = new FormData();
			formData.append("file", this.coverImg);
			formData.append("action", "uimg");
			UploadService.uploadCover(formData, event => {
				this.coverProgress = Math.round((100 * event.loaded) / event.total);
			}).then(data => {
				this.message = data.msg;
				if(data && data.err >= 0) {
					let rsFile = data.listFile[0];
					this.coverUrl = rsFile.url;
					this.cover = rsFile.name;
					this.successful = true;
					setTimeout(() => {
						// Hide the modal manually
						this.$nextTick(() => {
							this.$bvModal.hide('coverModal');
						});
					}, 2000);
				} else {
					this.successful = false;
				}
			});
		},
		existTag(tag) {
			return this.mapTag[tag];
		},
		addTag(e) {
			//console.log('e.keyCode: ' + e.keyCode);
			if(this.tag) {
				this.tag = this.tag.trim();
			}
			// Press key Enter is addtag
			if(e.keyCode === 13 && this.tag){ // keyboard ENTER
				//add Tag
				if(this.existTag(this.tag)) {
					this.tagAlert = 'You have already added this tag';
					setTimeout(() => {
						this.tagAlert = '';
					}, 3000);
				} else {
					this.mapTag[this.tag] = this.tag;
					this.numTag++;
					this.tag = '';
				}
			}
		},
		deleteTag(ktag) {
			//console.log('ktag: ' + ktag);
			delete this.mapTag[ktag];
			this.numTag--;
		},
		handleInitEditor(e, editor) {
			this.editorInstance = editor;
		},
		handleKeyUpEditor(e, editor) {
			let wordcount = editor.plugins.wordcount;
			this.word = wordcount.body.getWordCount();
		},
		handleChangeEditor(e, editor) {
			let wordcount = editor.plugins.wordcount;
			this.word = wordcount.body.getWordCount();
			// console.log('handleChangeEditor: ' + this.word);
		},
		handleSetContentEditor(e, editor) {
			let wordcount = editor.plugins.wordcount;
			this.word = wordcount.body.getWordCount();
			// console.log('handleSetContentEditor: ' + this.word);
		},
		uploadPhotoContent(blobInfo, success, failure, progress) {
			// console.log('In uploadPhotoContent');
			let filename = blobInfo.filename();
			// console.log('filename: ' + filename);
			if (!(filename.includes('.jpg') || filename.includes('.png'))) {
				failure("Only accept file image type jpg or png");
				return;
			}
			
			let formData = new FormData();
			formData.append("file", blobInfo.blob(), blobInfo.filename());
			formData.append("action", "uimg");

			UploadService.uploadPhotoContent(formData, event => {
				progress(Math.round((100 * event.loaded) / event.total));
			}).then(data => {
				if(data && data.err >= 0) {
					let rsFile = data.listFile[0];
					success(rsFile.url);
				} else {
					failure(data.msg);
				}
			});
		},
		validatePost() {
			return this.title.length > 0 && this.cover.length > 0 && this.cover.length < 100 && this.content.length > 0 && this.description.length > 0 && this.description.length < 1000;
		},
		resetPost(){
			// console.log('In resetPost');
			this.submitted = false;
			this.loading = false;
			this.successful = false;
			this.message = '';
		},
		savePost() {
			// console.log('In savePost');
			this.message = '';
			this.submitted = true;
			this.loading = true;
			// replace multiple spaces with single space.
			this.title = this.title.trim();
			this.title = this.title.replace(/  +/g, ' ');
			// replace multiple spaces with single space.
			this.description = this.description.trim();
			this.description = this.description.replace(/  +/g, ' ');
			// console.log('this.validatePost: ' + this.validatePost());
			if(!this.validatePost()) {
				this.message = "Inputs form invalid";
				this.successful = false;
				setTimeout(() => {
					this.loading = false;
					this.successful = false;
					this.message = '';
				}, 3000);
				return this.successful;
			}
			this.listtag = JSON.stringify(this.mapTag);
			let formData = {
				"title": this.title,
				"cover": this.cover,
				"content": this.content,
				"description": this.description,
				"listtag": this.listtag,
				"status": this.status, 
				"word": this.word
			};
			if(this.id.length > 0) { // Save Post
				// console.log('Save Post');
				formData["id"] = this.id;
				formData["action"] = "savePost";
			} else { // Add Post
				// console.log('Add Post');
				formData["action"] = "addPost";
			}
			return PostService.savePost(formData).then(data => {
				this.message = data.msg;
				if(data && data.err >= 0) {
					this.id = data.id;
					this.successful = true;
				} else {
					this.successful = false;
				}
				setTimeout(() => {
					this.resetPost();
				}, 3000);
				return this.successful;
			});
		},
		publishPost() {
			// console.log('In publishPost');
			this.status = "Active";
			if(!this.savePost()) {
				this.status = "Draft";
			}
		},
		getPost(id) {
			// console.log('In getPost: ' + id);
			PostService.getPost(id).then(data => {
				//console.log(data);
				if(data && data.err >= 0) {
					// console.log('=============== Set Data Post Edit ===============');
					let p = data.post;
					this.id = p.id;
					this.title = p.title;
					this.cover = p.cover;
					this.coverUrl = p.coverUrl;
					this.content = p.content;
					this.description = p.description;
					this.status = p.status;
					this.word = p.word;
					let tags = p.tags;
					if(tags && tags.length > 0) {
						this.numTag = tags.length;
						for(let i=0; i < tags.length; i++) {
							let t = tags[i];
							this.mapTag[t.name] = t.name;
						}
						this.listtag = JSON.stringify(this.mapTag);
					}
				}
			});
		},
		fetchData () {
			// if id exist them call method get post infomation.
			//console.log('this.$route.params.id: ' + this.$route.params.id);
			let pid = this.$route.params.id;
			if(pid && pid.length > 0){
				this.getPost(pid);
			} else { // Reset All Data
				this.resetAll();
			}
		}
	}
}
</script>
